import apiClient from './utils/AxiosClient'
import { BlockGuide, Library, TopicDoc } from './entities/topic.entity'
import { ProcessMessageCallback, fetchStream } from './utils/StreamProcessor'
import {
  TopicAndGuideToContentDTO,
  TopicLibraryAndGuideToContentDTO,
  TopicMetaToOutcomesDTO,
} from './API.dto'
import {
  CreateLibraryDto,
  CreateTopicDto,
  GenMarkingSchemeDTO,
  GenerateQuestionsDTO,
  MarkAnswerDTO,
  MetaLibraryToNotesDTO,
  ReviewContentDTO,
  ReviseContentDTO,
  TopicToGuidesDTO,
} from './entities/topic.dto'

// for streaming
const lambdaUrl = process.env.REACT_APP_CONTENT_LAMBDA_URL

export const API = {
  getAllTopicDocs: async (): Promise<TopicDoc[] | undefined> => {
    let url = `/content/topic`
    const response: any = await apiClient.get(url)
    return response.data
  },
  getAllLibraries: async (): Promise<Library[] | undefined> => {
    let url = `/content/library/`
    const response: any = await apiClient.get(url)
    return response.data
  },
  getTopicById: async (topicId: string): Promise<TopicDoc | undefined> => {
    let url = `/content/topic/${topicId}`
    const response: any = await apiClient.get(url)
    console.log('response', response)
    if (typeof response.data._id === 'string') {
      return response.data
    } else {
      console.log('no data')
      return undefined
    }
  },
  createTopic: async (
    createTopicDto: CreateTopicDto,
  ): Promise<TopicDoc | undefined> => {
    let url = `/content/topic`
    const response: any = await apiClient.post(url, createTopicDto)
    return response.data
  },
  updateTopic: async (topicDoc: TopicDoc): Promise<TopicDoc | undefined> => {
    let url = `/content/topic/${topicDoc._id}`
    const response: any = await apiClient.put(url, topicDoc)
    return response.data
  },
  getLibraryById: async (libraryId: string): Promise<Library | undefined> => {
    let url = `/content/library/${libraryId}`
    const response: any = await apiClient.get(url)
    console.log('response', response)
    if (typeof response.data._id === 'string') {
      return response.data
    } else {
      console.log('no data')
      return undefined
    }
  },
  createLibrary: async (
    createLibraryDto: CreateLibraryDto,
  ): Promise<Library | undefined> => {
    let url = `/content/library`
    const response: any = await apiClient.post(url, createLibraryDto)
    return response.data
  },
  updateLibrary: async (library: Library): Promise<Library | undefined> => {
    let url = `/content/library/${library._id}`
    const response: any = await apiClient.put(url, library)
    return response.data
  },

  topicToGuides: async (
    dto: TopicToGuidesDTO,
  ): Promise<BlockGuide[] | undefined> => {
    let url = `/prompt/notes`
    const response: any = await apiClient.post(url, dto)
    return response.data
  },

  topicAndGuideToContent: async (
    dto: TopicAndGuideToContentDTO,
  ): Promise<string | undefined> => {
    let url = `/prompt/content`
    const response: any = await apiClient.post(url, dto)
    return response.data
  },

  topicMetaToOutcomes: async (
    dto: TopicMetaToOutcomesDTO,
  ): Promise<string[] | undefined> => {
    let url = `/prompt/outcomes`
    const response: any = await apiClient.post(url, dto)
    return response.data
  },

  topicMetaToOutcomesStream: async (
    dto: TopicMetaToOutcomesDTO,
    processMessageCallback: ProcessMessageCallback,
    params: any,
  ): Promise<any> => {
    const url = `${lambdaUrl}/text/outcomes` // Replace with your actual endpoint
    await fetchStream(url, dto, processMessageCallback, params)
  },

  topicAndGuideToContentStream: async (
    dto: TopicAndGuideToContentDTO,
    processMessageCallback: ProcessMessageCallback,
    params: any,
  ): Promise<any> => {
    const url = `${lambdaUrl}/text/content` // Replace with your actual endpoint
    await fetchStream(url, dto, processMessageCallback, params)
  },

  metaToNotesStream: async (
    dto: TopicToGuidesDTO,
    processMessageCallback: ProcessMessageCallback,
    params: any,
  ): Promise<any> => {
    const url = `${lambdaUrl}/text/notes` // Replace with your actual endpoint
    await fetchStream(url, dto, processMessageCallback, params)
  },

  reviewWritingStream: async (
    dto: ReviewContentDTO,
    processMessageCallback: ProcessMessageCallback,
    params?: any,
  ): Promise<any> => {
    const url = `${lambdaUrl}/text/review` // Replace with your actual endpoint
    await fetchStream(url, dto, processMessageCallback, params)
  },

  reviseWritingStream: async (
    dto: ReviseContentDTO,
    processMessageCallback: ProcessMessageCallback,
    params?: any,
  ): Promise<any> => {
    const url = `${lambdaUrl}/text/revise` // Replace with your actual endpoint
    await fetchStream(url, dto, processMessageCallback, params)
  },

  metaLibraryToNotesStream: async (
    dto: MetaLibraryToNotesDTO,
    processMessageCallback: ProcessMessageCallback,
    params: any,
  ): Promise<any> => {
    const url = `${lambdaUrl}/text/library/notes` // Replace with your actual endpoint
    await fetchStream(url, dto, processMessageCallback, params)
  },

  topicLibraryAndGuideToContentStream: async (
    dto: TopicLibraryAndGuideToContentDTO,
    processMessageCallback: ProcessMessageCallback,
    params: any,
  ): Promise<any> => {
    const url = `${lambdaUrl}/text/library/content` // Replace with your actual endpoint
    await fetchStream(url, dto, processMessageCallback, params)
  },

  //////////////////////////
  // Questions
  //////////////////////////
  generateQuestionsStream: async (
    dto: GenerateQuestionsDTO,
    processMessageCallback: ProcessMessageCallback,
    params: any,
  ): Promise<any> => {
    const url = `${lambdaUrl}/text/questions` // Replace with your actual endpoint
    await fetchStream(url, dto, processMessageCallback, params)
  },

  genMarkingSchemeStream: async (
    dto: GenMarkingSchemeDTO,
    processMessageCallback: ProcessMessageCallback,
    params: any,
  ): Promise<any> => {
    const url = `${lambdaUrl}/text/markingscheme` // Replace with your actual endpoint
    await fetchStream(url, dto, processMessageCallback, params)
  },

  markAnswerStream: async (
    dto: MarkAnswerDTO,
    processMessageCallback: ProcessMessageCallback,
    params: any,
  ): Promise<any> => {
    const url = `${lambdaUrl}/text/mark` // Replace with your actual endpoint
    await fetchStream(url, dto, processMessageCallback, params)
  },

  // metaLibraryToGuides: async (
  //   dto: MetaLibraryToNotesDTO,
  // ): Promise<BlockGuide[] | undefined> => {
  //   let url = `/prompt/notes`
  //   const response: any = await apiClient.post(url, dto)
  //   return response.data
  // },
  /*topicAndGuideToContentSSE: (dto: TopicAndGuideToContentDTO): any => {
    const eventSource = new EventSource(`${apiClient.getUri()}/text/stream`)

    eventSource.onopen = (event) => {
      console.log('EventSource opened:', event)
    }
    // Event listener for incoming messages
    eventSource.onmessage = (event) => {
      // Parse the event data and handle accordingly
      console.log('Received message:', event.data)
      if (event.data === '␄') {
        eventSource.close()
      }
    }

    // Handle errors
    eventSource.onerror = (error) => {
      console.log('EventSource failed:', error)
      eventSource.close()
    }
  },*/

  dummyCall: async (delay: number) =>
    new Promise((resolve) => setTimeout(() => resolve('ok'), delay)),
  // const result1 = await APIQueue.getInstance().enqueue(() => API.dummyCall(2000))
}
