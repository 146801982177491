import React, { useRef } from 'react'
import { Popup } from './Popup'
import { Button } from '@mui/material'
import { PopupContentType, useUIContext } from 'context/UIProvider'
import MergedDoc from './MergedDoc'
import styles from './PopupExport.module.css'

interface PopupExportProps {
  // Add any props you need here
}

const PopupExport: React.FC<PopupExportProps> = () => {
  // Add your component logic here
  const { setPopupContentType } = useUIContext()

  const contentRef = useRef<HTMLDivElement>(null)
  const [isCopied, setIsCopied] = React.useState<boolean>(false)

  const handleCopy = () => {
    if (contentRef.current) {
      // Access the content from the ContentComponent using the ref
      // const contentToCopy = contentRef.current.innerText
      const contentToCopy = contentRef.current.innerHTML

      // Perform the copy operation
      const tempElement = document.createElement('div')
      tempElement.innerHTML = contentToCopy
      document.body.appendChild(tempElement)

      const range = document.createRange()
      range.selectNode(tempElement)
      window.getSelection()?.removeAllRanges()
      window.getSelection()?.addRange(range)

      document.execCommand('copy')
      window.getSelection()?.removeAllRanges()

      document.body.removeChild(tempElement)

      // You can provide feedback to the user or perform other actions
      //   console.log('contentToCopy', contentToCopy)
      setIsCopied(true)
    }
  }

  const downloadInnerHtml = async (fileName: string) => {
    if (contentRef.current) {
      const contentToCopy = contentRef.current.innerHTML
    }
  }

  const downloadDocx = async () => {
    // Load the DOCX template
    downloadInnerHtml('output.docx')
  }

  return (
    <Popup
      fullScreen={true}
      popupName={PopupContentType.Export}
      buttons={
        <>
          <Button
            className={styles.dismiss}
            variant="outlined"
            onClick={() => {
              setPopupContentType(PopupContentType.None)
            }}
          >
            Back
          </Button>
          <Button
            className={styles.confirm}
            variant="contained"
            onClick={() => {
              if (isCopied) {
                return
              }
              handleCopy()
              //   downloadDocx()
              setTimeout(() => {
                setPopupContentType(PopupContentType.None)
                setIsCopied(false)
              }, 500)
            }}
            // disabled={isCopied}
          >
            {isCopied ? 'Copied' : 'Copy & Go Back'}
          </Button>
        </>
      }
    >
      <MergedDoc contentRef={contentRef} />
    </Popup>
  )
}

export default PopupExport
