// MenuListComposition.jsx
import React, { useState, useEffect, useRef, useCallback } from 'react'
import styles from './FileMenu.module.css'
import { FileMenuMode, useDataContext } from 'context/DataProvider'

import { useTransition, animated } from '@react-spring/web'
import { API } from 'apis/API'
import { TopicDoc } from 'apis/entities/topic.entity'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import {
  ListItem,
  ListItemButton,
  ListItemText,
  List,
  TextField,
  Button,
} from '@mui/material'
import { useUIContext } from 'context/UIProvider'
import { RemoveScroll } from 'react-remove-scroll'
import { useNavigate } from 'react-router-dom'

export default function FileMenu() {
  // const [open, setOpen] = React.useState(false)
  const navigate = useNavigate()
  const [isVisible, setIsVisible] = useState(true)
  const {
    isFileMenuOpen,
    setIsFileMenuOpen,
    loadTopicById,
    fileMenuMode,
    getNewTopicDoc,
    setTopicDoc,
    setLibrary,
  } = useDataContext()
  const { isPageLoading, isModalOpen, setIsPageLoading, setIsModalOpen } =
    useUIContext()
  const [fileName, setFileName] = useState('')
  const createFileNameInputRef = useRef<HTMLInputElement | null>(null)

  //content
  const [topics, setTopics] = useState<TopicDoc[]>([])

  const transitions = useTransition(isVisible, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 300 },
    onRest: () => {
      if (!isVisible) {
        setIsFileMenuOpen(false)
      }
    },
  })

  useEffect(() => {
    //do some when isFileMenuOpen is set to true externally
    if (isFileMenuOpen) {
      open()
      if (fileMenuMode === FileMenuMode.new) {
        setFileName('')
        setTimeout(() => {
          createFileNameInputRef.current?.focus()
        }, 100)
      } else if (fileMenuMode === FileMenuMode.load) {
        fetchAllTopics()
      }
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFileMenuOpen])

  const handleCancel = () => {
    console.log('handleCancel')
    close()
  }

  const close = () => {
    setIsVisible(false)
  }
  const open = () => {
    setIsVisible(true)
  }

  const handleKeyDownCapture = (event: React.KeyboardEvent) => {
    // Check if the Enter key is pressed
    if (event.key === 'Enter') {
      // Prevent the default behavior of the Enter key (e.g., form submission)
      event.preventDefault()

      // Your custom action when Enter key is pressed
      console.log('Enter key pressed!')
      triggerCreateNew()
    }
  }

  //// topic content logic

  const fetchAllTopics = useCallback(async () => {
    const allTopics = await API.getAllTopicDocs()
    setTopics(allTopics ?? [])
    // console.log('allTopics', allTopics)
  }, [])

  const handleClickItem = (index: number) => {
    console.log('handleClickItem', topics[index]._id)
    setTopicDoc(undefined)
    setLibrary(undefined)
    const docId = topics[index]._id
    navigate(`/${docId}`)
    loadTopicById(docId)
    close()
  }
  // function renderRow(props: ListChildComponentProps) {
  //   const { index } = props

  //   return (
  //     <ListItem
  //       key={index}
  //       component="div"
  //       disablePadding
  //       onClick={() => handleClickItem(index)}
  //     >
  //       <ListItemButton>
  //         <ListItemText primary={`${topics[index].name}`} />
  //       </ListItemButton>
  //     </ListItem>
  //   )
  // }

  useEffect(() => {
    if (isVisible) {
      setIsModalOpen(true)
    } else {
      setIsModalOpen(false)
    }

    return () => {
      setIsModalOpen(false)
    }
  }, [isVisible])

  const triggerCreateNew = async () => {
    console.log('triggerCreateNew', fileName)
    if (fileMenuMode !== FileMenuMode.new || fileName.trim() === '') {
      return
    }
    setTopicDoc(undefined)
    setLibrary(undefined)
    const createTopicDto = { name: fileName, fileName: fileName }
    const result = await API.createTopic(createTopicDto)
    if (result) {
      setTopicDoc(result)
    }

    setFileName('')
    close()
  }

  const handleClickCreateNew = async () => {
    triggerCreateNew()
  }
  const handleNewNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFileName(event.target.value)
  }

  const renderContent = () => {
    return (
      <>
        {/* <FixedSizeList
          height={400}
          width={360}
          itemSize={46}
          itemCount={topics.length}
          overscanCount={5}
        >
          {renderRow}
        </FixedSizeList> */}

        <List className={styles.menubody}>
          {topics.map((topic, index) => (
            <ListItem
              key={index}
              component="div"
              disablePadding
              onClick={() => handleClickItem(index)}
            >
              <ListItemButton>
                <ListItemText primary={`${topic.fileName}`} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </>
    )
  }

  return (
    <>
      {isFileMenuOpen && (
        <RemoveScroll>
          <div className={styles.fullscreen}>
            {transitions(
              (animatedStyles, item) =>
                item && (
                  <animated.div style={animatedStyles}>
                    <div className={styles.layout}>
                      <div
                        className={styles.background}
                        onClick={handleCancel}
                      />

                      <div className={styles.container}>
                        {fileMenuMode === FileMenuMode.load && (
                          <>
                            <div className={styles.menuheader}>Open File</div>
                            <div className={styles.divider}></div>

                            {renderContent()}
                          </>
                        )}
                        {fileMenuMode === FileMenuMode.new && (
                          <>
                            <div className={styles.menuheader}>New File</div>
                            <div className={styles.inputcontent}>
                              <input
                                ref={createFileNameInputRef}
                                className={styles.textarea}
                                value={fileName}
                                onChange={handleNewNameChange}
                                onKeyDownCapture={handleKeyDownCapture}
                              ></input>
                              <Button
                                onClick={handleClickCreateNew}
                                className={styles.button}
                              >
                                Create
                              </Button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </animated.div>
                ),
            )}
          </div>
        </RemoveScroll>
      )}
    </>
  )
}

//scroll
// import InfiniteScroll from 'react-infinite-scroll-component'
