import React, { useRef } from 'react'
import { Popup, TransitionUp } from './Popup'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { PopupContentType, useUIContext } from 'context/UIProvider'
import MergedDoc from './MergedDoc'
import styles from './PopupConfirm.module.css'
import { on } from 'events'

// interface CallbackFunction {
//   (): void // Modify the function signature as needed
// }

interface PopupConfirmProps {
  // Add any props you need here
  isOpen: boolean
  title?: string
  description: string
  onConfirm: () => void
  onClose: () => void
}

const PopupConfirm: React.FC<PopupConfirmProps> = ({
  title,
  description,
  onConfirm,
  onClose,
  isOpen,
}) => {
  // Add your component logic here
  // const [isOpen, setIsOpen] = React.useState<boolean>(true)

  const handleClose = () => {
    onClose()
  }
  const handleConfirm = () => {
    onClose()
    onConfirm()
  }

  const buttons = (
    <>
      <Button
        className={styles.dismiss}
        variant="outlined"
        onClick={() => handleClose()}
      >
        Back
      </Button>
      <Button
        className={styles.confirm}
        variant="contained"
        onClick={handleConfirm}
        // disabled={isCopied}
      >
        OK
      </Button>
    </>
  )

  return (
    <Dialog
      fullScreen={false}
      open={isOpen}
      TransitionComponent={TransitionUp}
      //   keepMounted
      onClose={handleClose}
      maxWidth="xs"
      aria-describedby="alert-dialog-merged-doc"
      PaperProps={{
        sx: {
          borderRadius: '16px',
          paddingBottom: '16px',
          paddingLeft: '16px',
          paddingRight: '16px',
          paddingTop: '8px',
          overflow: 'hidden',
        },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{description}</DialogContent>
      <DialogActions className={styles.actionbarcolor}>{buttons}</DialogActions>
    </Dialog>
  )
}

export default PopupConfirm
