export interface TopicDoc {
  _id: string
  name: string
  fileName: string
  notes?: string
  learningOutcomes: LearningOutcome[]
  introduction?: Block
  sections?: Block[]
  conclusion?: Block
  review?: Review
  libraryId?: string
  questions?: Question[]
}

export interface Review {
  comments: string
}

export interface LearningOutcome {
  // _id: string
  title: string
  notes?: string | null
}

export interface Block {
  _id?: string
  isLoading?: boolean
  body?: string
  notes?: string
}

//response from api
export interface BlockGuide {
  type: string
  name: string
  notes: string
  requireList: boolean
}

export interface Library {
  _id: string
  title: string
  content: string
}

export interface Question {
  id: string
  title: string
  objective?: string
  markingScheme?: string
  responses?: QuestionResponse[]
}

export interface QuestionResponse {
  id: string
  answer: string
  comment?: string
  score?: number | undefined
}

export const getStatelessTopicDoc = (topicDoc: TopicDoc): TopicDoc => {
  return {
    ...topicDoc,
    introduction: getStatelessBlock(topicDoc.introduction),
    sections: topicDoc.sections?.map((block) => getStatelessBlock(block)),
    conclusion: getStatelessBlock(topicDoc.conclusion),
    review: topicDoc.review,
    questions: topicDoc.questions,
  }
}

function getStatelessBlock(block: Block | undefined): Block {
  if (!block) return { _id: '', body: '', isLoading: false }
  return {
    ...block,
    isLoading: false,
  }
}
