import React, { useEffect, useRef } from 'react'
import { Popup } from './Popup'
import { Button } from '@mui/material'
import { PopupContentType, useUIContext } from 'context/UIProvider'
import styles from './PopupLibrary.module.css'
import { useDataContext } from 'context/DataProvider'
import { API } from 'apis/API'
import { create } from 'domain'
import LibraryEditor from './LibraryEditor'
import { CreateLibraryDto } from 'apis/entities/topic.dto'

interface PopupLibraryProps {
  // Add any props you need here
}

const PopupLibrary: React.FC<PopupLibraryProps> = () => {
  // Add your component logic here

  const {
    topicDoc,
    library,
    saveTopicDoc,
    setLibrary,
    loadLibraryById,
    createLocalLibrary,
  } = useDataContext()
  const { popupContentType, setPopupContentType } = useUIContext()
  const contentRef = useRef<HTMLDivElement>(null)

  const closePopup = () => {
    setPopupContentType(PopupContentType.None)
  }

  const handleClickSave = async () => {
    const lib = library
    if (!topicDoc?.libraryId && lib && !lib._id) {
      //no doc library online, has local library, no id
      //create new online library
      const firstNewlineIndex = lib?.content.indexOf('\n')
      let tempTitleFromContent = ''
      if (
        firstNewlineIndex !== -1 &&
        firstNewlineIndex > 10 &&
        firstNewlineIndex < 40
      ) {
        tempTitleFromContent = lib?.content.substring(0, firstNewlineIndex)
      } else {
        tempTitleFromContent = lib?.content.substring(0, 40)
      }

      console.log('tempTitleFromContent', tempTitleFromContent)
      tempTitleFromContent = tempTitleFromContent.trim()
      const title =
        tempTitleFromContent.length > 0
          ? tempTitleFromContent
          : topicDoc?.name ?? ''
      const dto: CreateLibraryDto = {
        title: title,
        content: lib.content,
      }
      // create new online library
      const library = await API.createLibrary(dto)
      if (library?._id) {
        if (topicDoc) {
          const newTopicDoc = { ...topicDoc }
          newTopicDoc.libraryId = library._id
          await saveTopicDoc(newTopicDoc)
        }
      }
      console.log('created new library')
    } else {
      // update online library
      if (lib) {
        const newLibrary = { ...lib }
        await API.updateLibrary(newLibrary)
        console.log('updated library')

        if (topicDoc) {
          const newTopicDoc = { ...topicDoc }
          newTopicDoc.libraryId = lib._id
          await saveTopicDoc(newTopicDoc)
        }
      }
    }
    closePopup()
  }

  useEffect(() => {
    const prepareLibrary = async () => {
      //if open
      if (popupContentType === PopupContentType.Library) {
        //open without library
        if (!library) {
          if (topicDoc?.libraryId) {
            // Load library from server
            await loadLibraryById(topicDoc.libraryId)
          } else {
            //create local library
            createLocalLibrary()
          }
        }
      }
    }

    prepareLibrary()
  }, [popupContentType, topicDoc?.libraryId, setLibrary])

  return (
    <Popup
      direction="left"
      fullScreen={true}
      popupName={PopupContentType.Library}
      buttons={
        <>
          <Button
            className={styles.dismiss}
            variant="outlined"
            onClick={() => {
              closePopup()
            }}
          >
            close
          </Button>
          <Button
            className={styles.confirm}
            variant="contained"
            onClick={() => {
              handleClickSave()
            }}
            // disabled={isCopied}
          >
            Save
          </Button>
        </>
      }
    >
      <LibraryEditor contentRef={contentRef} />
    </Popup>
  )
}

export default PopupLibrary
