import { ChangeEvent, Fragment, useEffect, useRef, useState } from 'react'
import { useDataContext } from '../context/DataProvider'
import styles from './QuizEditor.module.css'
import {
  Button,
  Fade,
  Grow,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import {
  Block,
  LearningOutcome,
  Question,
  QuestionResponse,
} from 'apis/entities/topic.entity'
import { TextareaAutosize } from '@mui/base/TextareaAutosize'

import { EditNote } from '@mui/icons-material'
import { Delete } from '@mui/icons-material'
import { Create } from '@mui/icons-material'
import { Clear } from '@mui/icons-material'
import { Add } from '@mui/icons-material'

//animations
import { TransitionGroup } from 'react-transition-group'
import Collapse from '@mui/material/Collapse'
import { API } from 'apis/API'
import { useUIContext } from 'context/UIProvider'
import { Tooltip } from 'react-tooltip'
import { APIQueue } from 'apis/utils/APIQueue'
import { LoadingIndicator } from './LoadingIndicator'
import PopupConfirm from './PopupConfirm'
import { TopicMetaToOutcomesDTO } from 'apis/API.dto'
import EditorTags from './EditorTags'
import {
  GenMarkingSchemeDTO,
  GenerateQuestionsDTO,
  MarkAnswerDTO,
} from 'apis/entities/topic.dto'

export default function QuizEditor() {
  const { step, topicDoc, setTopicDoc, preference, storePreference, library } =
    useDataContext()
  const {
    isModalOpen,
    isPageLoading,
    setIsModalOpen,
    setIsPageLoading,
    setWishName,
  } = useUIContext()

  const [isLoading, setIsLoading] = useState(false)

  enum ActionStep {
    NONE = 0,
    RESET = 1,
    GENERATE = 2,
    DONE = 3,
  }
  const [actionStep, setActionStep] = useState(ActionStep.NONE)

  //////////////////////////
  // Data
  //////////////////////////
  const resetQuestions = () => {
    const newTopicDoc = { ...topicDoc! }
    newTopicDoc.questions = []
    setTopicDoc(newTopicDoc)
    // setTopicDoc({
    //   ...topicDoc!,
    //   questions: [],
    // })
  }

  //////////////////////////
  // Interactions
  //////////////////////////

  const handleClickGenerateQuestions = async () => {
    // generateQuestions()
    setActionStep(ActionStep.RESET)
  }
  const handleClickRemoveQuestion = (questionIndex: number) => {
    const newTopicDoc = { ...topicDoc! }
    const newQuestions = newTopicDoc.questions?.filter(
      (question, index) => index !== questionIndex,
    )
    newTopicDoc.questions = newQuestions
    setTopicDoc(newTopicDoc)
  }
  const handleClickAddQuestion = () => {
    const newTopicDoc = { ...topicDoc! }
    const newQuestion = {
      id: Math.random().toString(36).substr(2, 9),
      title: '',
      objective: '',
      markingScheme: undefined,
      responses: [],
    }
    newTopicDoc.questions?.push(newQuestion)
    setTopicDoc(newTopicDoc)
  }
  const handleClickGenMarkingScheme = async (questionIndex: number) => {
    const objective = topicDoc?.questions![questionIndex].objective
    const questionText = topicDoc?.questions![questionIndex].title

    setIsLoading(true)
    await APIQueue.getInstance().enqueue(async () => {
      const dto: GenMarkingSchemeDTO = {
        topic: topicDoc!.name,
        objective: objective!,
        question: questionText!,
      }
      await API.genMarkingSchemeStream(
        dto,
        processMarkingSchemeChunkCallback,
        questionIndex,
      )
    })
    setIsLoading(false)
  }

  const handleClickSubmitAnswer = async (
    questionId: number,
    answerId: number,
  ) => {
    console.log('handleClickSubmitAnswer')
    markAnswer(questionId, answerId)
  }

  const handleQuestionChange = (questionIndex: number, value: string) => {
    // setTopicDoc({ ...topicDoc!, notes: e.target.value })
    const newTopicDoc = { ...topicDoc! }
    newTopicDoc.questions![questionIndex].title = value
    setTopicDoc(newTopicDoc)
  }
  const handleObjectiveChange = (questionIndex: number, value: string) => {
    // setTopicDoc({ ...topicDoc!, notes: e.target.value })
    const newTopicDoc = { ...topicDoc! }
    newTopicDoc.questions![questionIndex].objective = value
    setTopicDoc(newTopicDoc)
  }

  const handleMarkingSchemeChange = (questionIndex: number, value: string) => {
    // setTopicDoc({ ...topicDoc!, notes: e.target.value })
    const newTopicDoc = { ...topicDoc! }
    newTopicDoc.questions![questionIndex].markingScheme = value
    setTopicDoc(newTopicDoc)
  }

  const handleAnswerChange = (
    questionId: number,
    answerId: number,
    value: string,
  ) => {
    const newTopicDoc = { ...topicDoc! }
    newTopicDoc.questions![questionId].responses![answerId].answer = value
    setTopicDoc(newTopicDoc)
  }

  const handleClickAddAnswer = (questionIndex: number) => {
    const newTopicDoc = { ...topicDoc! }
    const newAnswer = {
      id: Math.random().toString(36).substr(2, 9),
      answer: '',
      comment: undefined,
      score: undefined,
    }
    if (newTopicDoc.questions![questionIndex].responses === undefined) {
      newTopicDoc.questions![questionIndex].responses = []
    }
    newTopicDoc.questions![questionIndex].responses?.push(newAnswer)

    // const newQuestions = newTopicDoc.questions?.map((question) => {
    //   return {
    //     ...question,
    //     responses: [
    //       ...question.responses!,
    //       {
    //         id: Math.random().toString(36).substr(2, 9),
    //         answer: '',
    //         comment: undefined,
    //         score: undefined,
    //       },
    //     ],
    //   }
    // })
    // newTopicDoc.questions = newQuestions
    setTopicDoc(newTopicDoc)
  }
  const handleClickRemoveAnswer = (
    questionIndex: number,
    answerIndex: number,
  ) => {
    const newTopicDoc = { ...topicDoc! }
    const newAnswers = newTopicDoc.questions![questionIndex].responses?.filter(
      (response, index) => index !== answerIndex,
    )
    newTopicDoc.questions![questionIndex].responses = newAnswers
    setTopicDoc(newTopicDoc)
  }

  //////////////////////////
  // API
  //////////////////////////
  const processQuestionsChunkCallback = (
    message: string,
    accumulatedMessage: string,
    idPrefix?: string,
  ) => {
    console.log('processQuestionsChunkCallback', message)

    const segments = accumulatedMessage.split('§')

    const newQuestions = segments.map((segment, index) => {
      const segmentParts = segment.split('¶')
      const question = segmentParts[0].trim()
      const objective =
        segmentParts.length >= 2 ? segmentParts[1].trim() : undefined
      const markingScheme =
        segmentParts.length >= 3 ? segmentParts[2].trim() : undefined

      const questionId = (idPrefix ?? '') + index.toString()
      return {
        // ...newTopicDoc.questions![index],
        id: questionId,
        title: question,
        objective: objective,
        markingScheme: markingScheme,
        responses: [],
      }
      // newTopicDoc.questions?[index].body = section.trim()
    })
    console.log('newQuestions', newQuestions)

    const newTopicDoc = { ...topicDoc! }

    //remove questions with the same id in newQuestions
    newTopicDoc.questions = newTopicDoc.questions?.filter(
      (question) =>
        newQuestions.find((newQuestion) => newQuestion.id === question.id) ===
        undefined,
    )
    newTopicDoc.questions?.push(...newQuestions)

    //replace questions with the same id or add new questions
    // newQuestions.forEach((newQuestion, index) => {
    //   const existingQuestion = newTopicDoc.questions?.find(
    //     (question) => question.id === newQuestion.id,
    //   )
    //   if (existingQuestion) {
    //     newTopicDoc.questions?.splice(index, 1, newQuestion)
    //   } else {
    //     newTopicDoc.questions?.push(newQuestion)
    //   }
    // })

    // newTopicDoc.questions = newQuestions!
    setTopicDoc(newTopicDoc)
  }
  const processMarkingSchemeChunkCallback = (
    message: string,
    accumulatedMessage: string,
    index?: number,
  ) => {
    const newTopicDoc = { ...topicDoc! }

    newTopicDoc.questions![index!].markingScheme = accumulatedMessage

    setTopicDoc(newTopicDoc)
  }
  const processAnswerChunkCallback = (
    message: string,
    accumulatedMessage: string,
    params: any,
  ) => {
    console.log('params', params)
    const { questionId, answerId } = params

    console.log('processQuestionsChunkCallback', message)

    const newTopicDoc = { ...topicDoc! }

    const segment = accumulatedMessage
    const segmentParts = segment.split('¶')
    const comment = segmentParts[0].trim()
    const score = segmentParts.length >= 2 ? segmentParts[1].trim() : undefined
    // const existingQuestion = newTopicDoc.questions![index]
    // const existingQuestion = newTopicDoc.questions?
    // newPosExps[expIndex]?.reasons![reasonIndex]?.solutions?.[solutionIndex]
    // ?.response

    // newTopicDoc.questions?[index].body = section.trim()

    newTopicDoc.questions![questionId].responses![answerId].comment = comment
    newTopicDoc.questions![questionId].responses![answerId].score =
      score === undefined ? undefined : Number(score)

    setTopicDoc(newTopicDoc)
  }
  const generateQuestions = async () => {
    const { name, learningOutcomes } = topicDoc!

    setIsLoading(true)
    await APIQueue.getInstance().enqueue(async () => {
      const dto: GenerateQuestionsDTO = {
        topic: name,
        learningOutcome: learningOutcomes[0].title,
      }
      await API.generateQuestionsStream(
        dto,
        processQuestionsChunkCallback,
        Math.random().toString().substring(2, 8),
      )
    })
    setIsLoading(false)
  }
  const markAnswer = async (questionId: number, answerId: number) => {
    const learningOutcome = topicDoc?.learningOutcomes![0].title
    const questionText = topicDoc?.questions![questionId].title
    const answerText =
      topicDoc?.questions![questionId].responses![answerId].answer
    const markingScheme = topicDoc?.questions![questionId].markingScheme

    setIsLoading(true)
    await APIQueue.getInstance().enqueue(async () => {
      const dto: MarkAnswerDTO = {
        question: questionText!,
        learningOutcome: learningOutcome!,
        markingScheme: markingScheme!,
        answer: answerText!,
      }
      await API.markAnswerStream(dto, processAnswerChunkCallback, {
        questionId,
        answerId,
      })
    })
    setIsLoading(false)
  }

  //////////////////////////
  // Render
  //////////////////////////

  const renderAnswer = (
    response: QuestionResponse,
    questionIndex: number,
    answerIndex: number,
  ) => {
    return (
      <div className={styles.answercontainer}>
        <div>Test learner answer {answerIndex + 1}</div>
        <div className={styles.answer}>
          <TextareaAutosize
            className={`${styles.textarea} ${styles.answertext}`}
            placeholder="Input your answer here to be marked."
            minRows={2}
            onChange={(e) =>
              handleAnswerChange(questionIndex, answerIndex, e.target.value)
            }
            value={response.answer}
            data-tooltip-id="tooltip-3"
          />
          <Button
            disabled={isLoading || !response.answer.trim()}
            className={`${styles.button} ${styles.submit}`}
            onClick={() => {
              handleClickSubmitAnswer(questionIndex, answerIndex)
            }}
          >
            Mark
          </Button>
          <div className={styles.score}>{response.score}</div>
        </div>
        {response.comment ? (
          <div className={styles.commentcontainer}>
            Comments: {response.comment}
          </div>
        ) : (
          <></>
        )}

        <div className={`${styles.removeanswer}`}>
          <IconButton
            className={`${styles.iconbutton}`}
            onClick={() => handleClickRemoveAnswer(questionIndex, answerIndex)}
          >
            <Delete />
          </IconButton>
        </div>
      </div>
    )
  }

  const renderQuestion = (question: Question, questionIndex: number) => {
    return (
      <div className={styles.questioncontainer}>
        <div className={styles.questionhead}>
          <b>Assessment question {questionIndex + 1}</b>
          <div className={`${styles.removequestion}`}>
            {(topicDoc?.questions?.length ?? 0) >= 0 && (
              <IconButton
                className={`${styles.iconbutton}`}
                onClick={() => handleClickRemoveQuestion(questionIndex)}
              >
                <Delete />
              </IconButton>
            )}
          </div>
        </div>
        <TextareaAutosize
          className={`${styles.textarea} ${styles.questiontext}`}
          placeholder="Question"
          minRows={2}
          onChange={(e) => handleQuestionChange(questionIndex, e.target.value)}
          value={question.title}
        />
        <TextareaAutosize
          className={`${styles.textarea} ${styles.markingscheme}`}
          placeholder="Objective and constraints"
          minRows={2}
          onChange={(e) => handleObjectiveChange(questionIndex, e.target.value)}
          value={question.objective}
        />
        <div className={styles.markingschemecontainer}>
          <TextareaAutosize
            className={`${styles.textarea} ${styles.markingscheme}`}
            placeholder="Marking scheme"
            minRows={2}
            onChange={(e) =>
              handleMarkingSchemeChange(questionIndex, e.target.value)
            }
            value={question.markingScheme}
          />
          <div className={`${styles.genmarkingscheme}`}>
            <IconButton
              className={`${styles.iconbutton}`}
              onClick={() => handleClickGenMarkingScheme(questionIndex)}
            >
              <EditNote />
            </IconButton>
          </div>
          {/* <IconButton
            className={`${styles.iconbutton} ${styles.genmarkingscheme}`}
            aria-label="delete"
            size="small"
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton> */}
        </div>

        <TransitionGroup className={styles.answerscontainer}>
          {question.responses?.map((response, answerIndex) => {
            return (
              <Collapse
                key={`response-${questionIndex}-${response.id}`}
                // in={true}
              >
                {renderAnswer(response, questionIndex, answerIndex)}
              </Collapse>
            )
          })}
        </TransitionGroup>
        <Grow in={!isLoading}>
          <Button
            variant="outlined"
            startIcon={<Add />}
            disabled={isLoading}
            className={`${styles.addanswer}`}
            onClick={() => {
              handleClickAddAnswer(questionIndex)
            }}
          >
            Add A Test Answer
          </Button>
        </Grow>
      </div>
    )
  }

  //////////////////////////
  // useEffect
  //////////////////////////

  //init quiz
  // useEffect(() => {
  //   if (topicDoc) {
  //     if (topicDoc.questions === undefined || topicDoc.questions.length === 0) {
  //       resetQuestions()
  //     }
  //   }
  // }, [topicDoc])

  useEffect(() => {
    if (actionStep === ActionStep.RESET) {
      // resetQuestions()
      setActionStep(ActionStep.GENERATE)
    } else if (actionStep === ActionStep.GENERATE) {
      const asyncFunction = async () => {
        await generateQuestions()
        setActionStep(ActionStep.NONE)
      }
      asyncFunction()
    }
  }, [actionStep])

  return (
    <>
      <div className={`${styles.background}`} id="scroll-container">
        <div className={styles.scrollcontainer}>
          <Grow
            in={topicDoc !== undefined}
            style={{ transformOrigin: '50% 50vh 0' }}
          >
            <div className={styles.pagearea}>
              <EditorTags selectedIndex={2} />
              <div className={`${styles.pagecontainer}`}>
                {/* <div> */}
                <div>Quiz: {topicDoc?.name}</div>
                {/* <div>Target:</div> */}
                {topicDoc?.learningOutcomes.map((learningOutcome, index) => {
                  return index === 0 ? (
                    <div key={`lo-${index}`}>{learningOutcome.title}</div>
                  ) : (
                    <div key={`lo-${index}`}></div>
                  )
                })}
                {(topicDoc?.learningOutcomes.length ?? 0) > 1 && (
                  <Button
                    disabled={isLoading}
                    className={styles.button}
                    onClick={() => {
                      handleClickGenerateQuestions()
                    }}
                  >
                    Generate quiz questions
                  </Button>
                )}
                <TransitionGroup className={styles.questionscontainer}>
                  {topicDoc?.questions?.map((question, index) => {
                    return (
                      <Collapse key={`question-${question.id}`}>
                        {renderQuestion(question, index)}
                      </Collapse>
                    )
                  })}
                </TransitionGroup>
                <Button
                  className={styles.addquestion}
                  variant="contained"
                  startIcon={<Add />}
                  style={{ color: 'white' }}
                  onClick={() => {
                    handleClickAddQuestion()
                  }}
                >
                  Add A NEW Question
                </Button>
                {/* </div> */}
              </div>
            </div>
          </Grow>
        </div>
      </div>
    </>
  )
}
