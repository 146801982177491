import React, { useRef } from 'react'
import styles from './MergedDoc.module.css'
import { useDataContext } from 'context/DataProvider'
import { useUIContext } from 'context/UIProvider'

interface MergedDocProps {
  // Define the props for your component here
  contentRef: React.RefObject<HTMLDivElement>
}

const MergedDoc: React.FC<MergedDocProps> = ({ contentRef }) => {
  // Implement your component logic here
  const { topicDoc } = useDataContext()
  const {} = useUIContext()

  return (
    <div className={styles.pagecontainer} ref={contentRef}>
      <h1>{topicDoc?.name}</h1>

      <p>
        <b>Learning outcomes:</b>
      </p>
      {topicDoc?.learningOutcomes?.map((learningOutcome, index) => (
        <div key={`export-lo-${index}`}>
          <li>{learningOutcome.title}</li>
        </div>
      ))}
      <br />

      <p>
        <b>Introduction</b>
      </p>
      <p>{topicDoc?.introduction?.body}</p>
      <br />

      {topicDoc?.sections?.map((section, index) => {
        const paragraphs = section.body?.split('\n\n')
        const title = paragraphs?.shift() // Get the first line as the title
        const restOfContent = paragraphs?.join('\n\n') // Join the remaining paragraphs
        return (
          <div key={`export-section-${index}`}>
            <p>
              <b>{title}</b>
            </p>
            <p>{restOfContent}</p>
            <br />
          </div>
        )
      })}

      <p>
        <b>Conclusion</b>
      </p>
      <p>{topicDoc?.conclusion?.body}</p>
    </div>
  )
}

export default MergedDoc
