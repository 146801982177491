interface Job {
  id: number
  execute: () => Promise<any>
  resolve: (value?: any) => void
  reject: (reason?: any) => void
}

export interface QueueOptions {
  maxConcurrentJobs?: number // Make it optional
}

export class APIQueue {
  private static instance: APIQueue
  private jobQueue: Job[] = []
  private maxConcurrentJobs: number
  private activeJobs: number = 0

  private constructor(options: QueueOptions = {}) {
    this.maxConcurrentJobs = options.maxConcurrentJobs || 3 // Use default value of 2 if not provided
  }

  public static getInstance(options: QueueOptions = {}): APIQueue {
    if (!APIQueue.instance) {
      APIQueue.instance = new APIQueue(options)
    }
    return APIQueue.instance
  }

  public setMaxConcurrentJobs(maxConcurrentJobs: number): void {
    this.maxConcurrentJobs = maxConcurrentJobs
    this.processQueue() // Process the queue after updating the value
  }

  private async processQueue() {
    while (
      this.jobQueue.length > 0 &&
      this.activeJobs < this.maxConcurrentJobs
    ) {
      const job = this.jobQueue.shift()
      if (job) {
        this.activeJobs++

        try {
          const result = await job.execute()
          job.resolve(result)
        } catch (error) {
          job.reject(error)
          console.error(`Error processing job ${job.id}:`, error)
        } finally {
          this.activeJobs--
        }
      }
    }
  }

  public enqueue(apiFunction: () => Promise<any>): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const job: Job = {
        id: Date.now(),
        execute: apiFunction,
        resolve,
        reject,
      }

      this.jobQueue.push(job)
      await this.processQueue()
    })
  }
}
