export type ProcessMessageCallback = (
  message: string,
  accumulatedMessage: string,
  params?: any,
) => void

export const fetchStream = async (
  url: string,
  dto: any,
  processMessageCallback: ProcessMessageCallback,
  params: any,
): Promise<any> => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dto),
    })

    const reader = response.body!.getReader()
    const textDecoder = new TextDecoder()
    let partialChunk = ''
    let accumulatedMessage = '' // Declare the variable outside the loop

    const processJsonObject = (jsonString: string) => {
      if (jsonString.trim() !== '') {
        try {
          const jsonObject = JSON.parse(jsonString)
          const message = jsonObject.data.message

          accumulatedMessage = accumulatedMessage + message
          processMessageCallback(message, accumulatedMessage, params)
          // Handle the parsed JSON object
        } catch (error) {
          console.error('Error parsing JSON:', error)
        }
      }
    }

    while (true) {
      const { done, value } = await reader.read()

      if (done) {
        break
      }

      const decodedChunk = textDecoder.decode(value)
      const combinedData = partialChunk + decodedChunk

      const jsonObjects = combinedData.split('\n')

      // Process each JSON object
      jsonObjects.forEach(processJsonObject)

      // Save any remaining incomplete JSON object for the next iteration
      partialChunk = jsonObjects[jsonObjects.length - 1]
    }

    console.log('Stream closed')
  } catch (error) {
    console.error('Error streaming:', error)
  }
}
