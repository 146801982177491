// import * as url from 'url';
// import * as querystring from 'querystring';

export const isEmail = (email: string): boolean => {
  const expression: RegExp =
    /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/
  return expression.test(email)
}

export const getUrlParam = (param: string): string => {
  const currentPath = window.location.href
  // console.log('currentPath', currentPath)
  const parsedUrl = new URL(currentPath)
  // console.log('parsedUrl', parsedUrl)
  const params = new URLSearchParams(parsedUrl.search)
  const value = params.get(param) || ''
  return value
}

export const getWordCount = (
  text: string | (string | undefined)[] | undefined | null,
): number => {
  if (!text) return 0

  const flatText: string = Array.isArray(text)
    ? text.filter(Boolean).join(' ')
    : text.trim()

  if (!flatText) return 0

  // Define a regular expression pattern to split the text
  const wordPattern = /\b\w+\b/g

  // Match word boundaries with \b, and \w+ to match one or more word characters
  // 'g' flag ensures global search

  return flatText.match(wordPattern)?.length || 0
}
