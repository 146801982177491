import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { useUIContext } from 'context/UIProvider'
import React from 'react'
import styles from './FeaturePopup.module.css'

import Sparkles from 'react-sparkle'
import { red } from '@mui/material/colors'

import imgLightmode from 'images/lightmode.jpg'
import imgFiles from 'images/files.jpg'
import imgWriting from 'images/writing.jpg'
import imgInstructions from 'images/instructions.jpg'
import imgExport from 'images/export.jpg'

interface FeaturePopupProps {
  // Define props here
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

type Wishlist = {
  [key: string]: {
    title: string
    description: string
    image?: string
  }
}

const FeaturePopup: React.FC<FeaturePopupProps> = (props) => {
  // Component logic here
  const { wishName, setWishName } = useUIContext()
  const [sparkle, setSparkle] = React.useState<boolean>(false)

  const wishlist: Wishlist = {
    null: {
      title: 'none',
      description: 'none',
    },
    export: {
      title: 'Export',
      description: 'Export as html, pdf, etc.',
      image: imgExport,
    },
    lightMode: {
      title: 'Light Mode',
      description: 'Switch to light mode with a bright theme',
      image: imgLightmode,
    },
    suggest: {
      title: 'More Content Generation Approaches',
      description:
        'Suggest alternative versions, refine copy. More ways to improve writings...',
      image: imgWriting,
    },
    help: {
      title: 'Smart Assistant',
      description: 'Get help with realtime instructions.',
      image: imgInstructions,
    },
    saveAs: {
      title: 'File management',
      description: 'Tag, manage, copy files',
      image: imgFiles,
    },
  }

  const handleClose = () => {
    setWishName('')
  }

  const wishItem = wishlist[`${wishName || 'null'}`]

  return (
    <Dialog
      open={wishName ? true : false}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        sx: { borderRadius: '24px', padding: '16px', overflow: 'hidden' },
      }}
    >
      <DialogTitle>
        <b>{wishItem?.title}</b>
      </DialogTitle>

      <DialogContent sx={{ display:'flex', flexDirection:'column'}}>
        {/* <DialogContentText id="alert-dialog-slide-description"> */}
          <b>{wishItem?.description}</b>
          <br />
          {wishItem?.image && (
            <img
              className={styles.image}
              src={wishItem?.image}
              alt={wishItem?.title}
            />
          )}
          <br />
          This is not available for testing yet. Request to get this feature. 😊
        {/* </DialogContentText> */}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleClose}
          className={styles.dismiss}
        >
        Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleClose}
          className={styles.confirm}
          onMouseEnter={() => setSparkle(true)}
          onMouseLeave={() => setSparkle(false)}
        >
          {sparkle && ''} I need it {sparkle && ''}
        </Button>
      </DialogActions>
      <Sparkles
        flicker={false}
        flickerSpeed="slowest"
        count={100}
        fadeOutSpeed={30}
      ></Sparkles>
    </Dialog>
  )
}

export default FeaturePopup
