import FullScreenLayout from 'components/FullscreenLayout'
import { useDataContext } from '../context/DataProvider'
// import styles from "./Home.module.css";
import TopicEditor from 'components/TopicEditor'
import AppMenu from 'components/AppMenu'
import FileMenu from 'components/FileMenu'
import { LoadingIndicatorFullScreen } from 'components/LoadingIndicator'
import { Button, Fade } from '@mui/material'
import { PopupContentType, useUIContext } from 'context/UIProvider'
import FeaturePopup from 'components/FeaturePopup'
import MergedDoc from 'components/MergedDoc'
import { Popup } from 'components/Popup'
import PopupExport from 'components/PopupExport'
import PopupLibrary from 'components/PopupLibrary'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import QuizEditor from 'components/QuizEditor'

export default function Home() {
  const { topicDoc } = useDataContext()
  const { isPageLoading, setPopupContentType } = useUIContext()

  return (
    <FullScreenLayout>
      <AppMenu />
      <PopupExport />
      <PopupLibrary />

      <Routes>
        {/* <Route path="/support" element={<Support />} /> */}

        <Route path="/*" element={<>{topicDoc && <TopicEditor />}</>} />
        <Route path="/quiz/*" element={<QuizEditor />} />
      </Routes>

      {/* <div className={`${styles.container} ${styles.highlight}`}>Home</div> */}
      <FileMenu />

      <LoadingIndicatorFullScreen />
      <FeaturePopup />
    </FullScreenLayout>
  )
}
