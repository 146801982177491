import React from 'react'
import styles from './EditorTags.module.css'
import { useNavigate } from 'react-router-dom'
import { useDataContext } from 'context/DataProvider'

interface EditorTagsProps {
  selectedIndex: number
}

const tags = [
  {
    name: 'Content',
    url: '',
  },
  {
    name: 'Quiz',
    url: '/quiz',
  },
]

const EditorTags: React.FC<EditorTagsProps> = ({ selectedIndex }) => {
  const handleClickTag = (index: number) => {
    navigate(tags[index].url + `/${topicDoc?._id}`)
  }
  const navigate = useNavigate()

  const { topicDoc } = useDataContext()
  return (
    <div className={styles.tagscontainer}>
      {tags.map((tag, index) => (
        <div
          onClick={() => handleClickTag(index)}
          key={`tag-${index}`}
          className={`${styles.tag} ${
            selectedIndex === index + 1 ? styles.selected : ''
          }`}
        >
          {tag.name}
        </div>
      ))}
    </div>
  )
}

export default EditorTags
