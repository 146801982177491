import ReactLoading from 'react-loading'
import styles from './LoadingIndicator.module.css'
import React from 'react'
import { useUIContext } from 'context/UIProvider'
import { RemoveScroll } from 'react-remove-scroll'
import { Fade } from '@mui/material'

interface LoadingIndicatorProps {
  className?: string
}

export const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  className,
  ...props
}) => (
  <ReactLoading
    className={`top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 ${
      className || ''
    }`}
    type={'spinningBubbles'}
    color={'#eeeeee'}
    height={'64px'}
    width={'64px'}
    {...props}
  />
)

export const LoadingIndicatorFullScreen = () => {
  const { isPageLoading } = useUIContext()

  return (
    <>
      <Fade in={isPageLoading} unmountOnExit>
        {/* <RemoveScroll> */}
          <div className={styles.fullscreen}>
            <div className={styles.layout}>
              <div className={styles.background} />

              <LoadingIndicator className={styles.indicator} />
            </div>
          </div>
        {/* </RemoveScroll> */}
      </Fade>
    </>
  )
}
