import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useRef,
  useEffect,
} from 'react'

import { isProduction } from '../utils/EnvUtils'
import {
  Library,
  TopicDoc,
  getStatelessTopicDoc,
} from 'apis/entities/topic.entity'
import { API } from 'apis/API'
import { toastSuccess } from 'utils/toast'

export interface URLParams {
  [key: string]: string
}
interface DataContextProps {
  step: number
  setStep: (step: number) => void

  topicDoc: TopicDoc | undefined
  setTopicDoc: (topicDoc: TopicDoc | undefined) => void

  library: Library | undefined
  setLibrary: (topicLibrary: Library | undefined) => void

  isFileMenuOpen: boolean
  setIsFileMenuOpen: (isFileMenuOpen: boolean) => void

  loadTopicById: (topicId: string) => void

  fileMenuMode: FileMenuMode
  setFileMenuMode: (fileMenuMode: FileMenuMode) => void

  getNewTopicDoc: (filename: string) => TopicDoc

  preference: Preference
  storePreference: (updatedPreference: Preference) => void

  slug: string | null
  urlParams: URLParams | null

  saveTopicDoc: (newTopicDoc?: TopicDoc) => Promise<TopicDoc | undefined>
  loadLibraryById: (libraryId: string) => void
  createLocalLibrary: () => void
}

export enum FileMenuMode {
  closed,
  load,
  new,
}

export interface Preference {
  theme: string
  toolTip: number
}

const Context = createContext<DataContextProps | null>(null)

export const useDataContext = (): DataContextProps => {
  const context = useContext(Context)
  if (!context) {
    throw new Error('data context must be inside a provider')
  }
  return context
}

type Props = {
  children: ReactNode
}

const defaultTopicDoc: TopicDoc = {
  _id: '',
  fileName: '',
  name: '',
  learningOutcomes: [{ title: '' }],
  introduction: { _id: '', body: '' },
  sections: [{ _id: '', notes: '', body: '' }],
  conclusion: { _id: '', body: '' },
}
export const DataProvider = ({ children }: Props) => {
  const [step, setStep] = useState(0)
  const [isFileMenuOpen, setIsFileMenuOpen] = useState(false)
  const [topicDoc, setTopicDoc] = useState<TopicDoc | undefined>()
  const [library, setLibrary] = useState<Library | undefined>()
  const [fileMenuMode, setFileMenuMode] = useState<FileMenuMode>(
    FileMenuMode.load,
  )
  const [preference, setPreference] = useState<Preference>({
    theme: '',
    toolTip: 1,
  })
  const [slug, setSlug] = useState<string | null>(null)
  const [urlParams, setUrlParams] = useState<URLParams | null>(null)

  const extractUrlParams = (): URLParams | null => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params: URLParams = {}

    urlSearchParams.forEach((value, key) => {
      params[key] = value
    })

    return params
  }
  const extractSlug = (): string | null => {
    const match = window.location.pathname.match(/\/([^\/]+)$/)
    return match ? match[1] : null
  }

  const getPreference = () => {
    const storedPreferenceJSON = localStorage.getItem('userPreference')
    if (storedPreferenceJSON) {
      console.log('storedPreferenceJSON', storedPreferenceJSON)
      const storedPreference: Preference = JSON.parse(storedPreferenceJSON)
      setPreference(storedPreference)
    }
  }

  // Function to set the preference object to localStorage and update state
  const storePreference = (updatedPreference: Preference) => {
    localStorage.setItem('userPreference', JSON.stringify(updatedPreference))
    setPreference(updatedPreference)
  }

  const loadTopicById = async (topicId: string) => {
    console.log('loadTopicById', topicId)
    const loadedTopicDoc = await API.getTopicById(topicId)
    console.log('loadedTopicDoc', loadedTopicDoc)
    if (loadedTopicDoc) {
      // const newTopicDoc = { ...defaultTopicDoc, ...topicDoc }
      setTopicDoc(loadedTopicDoc)
    }
  }

  const createLocalLibrary = () => {
    const newLibrary = {
      _id: '',
      title: topicDoc?.name || '',
      content: '',
    }
    setLibrary(newLibrary)
  }
  const loadLibraryById = async (libraryId: string) => {
    console.log('loadLibraryById', libraryId)
    const loadedLibrary = await API.getLibraryById(libraryId)
    console.log('loadedLibrary', loadedLibrary)
    if (loadedLibrary) {
      // const newTopicDoc = { ...defaultTopicDoc, ...topicDoc }
      setLibrary(loadedLibrary)
    }
  }

  const getNewTopicDoc = (fileName: string): TopicDoc => {
    const newTopicDoc = {
      ...defaultTopicDoc,
      fileName: fileName,
      name: fileName,
    }
    return newTopicDoc
  }

  const saveTopicDoc = async (newTopicDoc?: TopicDoc) => {
    const docToSave = newTopicDoc ?? topicDoc
    if (!docToSave) {
      return
    }
    console.log('saveTopicDoc', docToSave)

    const statelessTopicDoc = getStatelessTopicDoc(docToSave)
    console.log('statelessTopicDoc', statelessTopicDoc)
    const result = await API.updateTopic(statelessTopicDoc)
    console.log('handleClickSave', result)

    return result
  }

  useEffect(() => {
    setUrlParams(extractUrlParams())
    const slug = extractSlug()
    setSlug(slug)
    if (slug) {
      loadTopicById(slug!)
    } else {
      setIsFileMenuOpen(true)
    }

    getPreference()
  }, [])

  const providerValue = {
    step,
    setStep,
    topicDoc,
    setTopicDoc,
    isFileMenuOpen,
    setIsFileMenuOpen,
    loadTopicById,
    fileMenuMode,
    setFileMenuMode,
    getNewTopicDoc,
    preference,
    storePreference,
    slug,
    urlParams,
    library,
    setLibrary,
    saveTopicDoc,
    loadLibraryById,
    createLocalLibrary,
  }

  return <Context.Provider value={providerValue}>{children}</Context.Provider>
}
