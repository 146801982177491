import { ChangeEvent, Fragment, useEffect, useRef, useState } from 'react'
import { useDataContext } from '../context/DataProvider'
import styles from './TopicEditor.module.css'
import { Button, Fade, Grow, TextField, Typography } from '@mui/material'
import { Block, LearningOutcome } from 'apis/entities/topic.entity'
import { TextareaAutosize } from '@mui/base/TextareaAutosize'

//animations
import { TransitionGroup } from 'react-transition-group'
import Collapse from '@mui/material/Collapse'
import { API } from 'apis/API'
import { useUIContext } from 'context/UIProvider'
import { Tooltip } from 'react-tooltip'
import { APIQueue } from 'apis/utils/APIQueue'
import { LoadingIndicator } from './LoadingIndicator'
import PopupConfirm from './PopupConfirm'
import { TopicMetaToOutcomesDTO } from 'apis/API.dto'
import EditorTags from './EditorTags'

export default function TopicEditor() {
  const { step, topicDoc, setTopicDoc, preference, storePreference, library } =
    useDataContext()
  const {
    isModalOpen,
    isPageLoading,
    setIsModalOpen,
    setIsPageLoading,
    setWishName,
  } = useUIContext()
  const [isLoadingOutcomes, setIsLoadingOutcomes] = useState(false)
  const [outcomeFocus, setOutcomeFocus] = useState(0)
  const sectionFocus = useRef(0)
  const [sectionIdleTimerId, setSectionIdleTimerId] =
    useState<NodeJS.Timeout | null>(null)
  const outcomeRef = useRef<HTMLTextAreaElement>(null) //for auto focus
  enum Actions {
    NONE,
    ADD_OUTCOME,
    ADD_SECTION,
    // Add more actions as needed
  }
  const [lastAction, setLastAction] = useState(Actions.NONE)
  const [tooltipStates, setTooltipStates] = useState([
    preference.toolTip,
    1,
    0,
    0,
    0,
    0,
  ]) //first one is master
  const tooltipElementRef = useRef(null)
  //   const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
  //     setText(event.target.value);
  //     event.target.style.height = 'auto';
  //     event.target.style.height = `${event.target.scrollHeight}px`;
  //   };

  const [confirmCallback, setConfirmCallback] = useState<CallableFunction>(
    () => {},
  )
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)

  const streamedNotes = useRef<string>('')
  const streamedOutcomes = useRef<string>('')
  // const [shouldGenerateAll, setShouldGenerateAll] = useState(false)

  enum ActionStep {
    NONE = 0,
    RESET_OUTCOMES = 1,
    GENERATE_OUTCOMES = 2,
    RESET_CONTENT_FROM_SCRATCH = 3,
    GENERATE_NOTES_FROM_SCRATCH = 4,
    GENERATE_CONTENT_FROM_SCRATCH = 5,
    REVIEW_CONTENT = 6,
    REVISE_CONTENT = 7,
    RESET_CONTENT_FROM_LIBRARY = 8,
    GENERATE_NOTES_FROM_LIBRARY = 9,
    GENERATE_CONTENT_FROM_LIBRARY = 10,
    DONE = 11,
  }
  const [actionStep, setActionStep] = useState(ActionStep.NONE)

  // const [comments, setComments] = useState('')

  const handleClickAddOutcome = () => {
    const newTopicDoc = { ...topicDoc! }
    newTopicDoc.learningOutcomes.push({ title: '' })
    setTopicDoc(newTopicDoc)
    setLastAction(Actions.ADD_OUTCOME)
  }

  const processOutcomesChunkCallback = (
    message: string,
    accumulatedMessage: string,
    index?: number,
  ) => {
    // console.log('processOutcomesChunkCallback', message)
    // streamedOutcomes.current = streamedOutcomes.current + message
    const parts = accumulatedMessage.split('§')
    const newTopicDoc = { ...topicDoc! }
    const newOutcomes = parts.map((outcomeString) => {
      return { title: outcomeString.trim() }
    })
    newTopicDoc.learningOutcomes = newOutcomes
    setTopicDoc(newTopicDoc)
  }

  const generateLearningOutcomes = async () => {
    const dto: TopicMetaToOutcomesDTO = {
      name: topicDoc!.name,
      audience: 'full time working employees',
      course: '',
    }

    setIsLoadingOutcomes(true)
    streamedOutcomes.current = ''
    await APIQueue.getInstance().enqueue(
      async () =>
        // const { name, notes, learningOutcomes } = topicDoc!
        await API.topicMetaToOutcomesStream(
          dto,
          processOutcomesChunkCallback,
          0,
        ),
    )
    streamedOutcomes.current = ''
    setIsLoadingOutcomes(false)
  }

  const handleClickSuggestLearningOutcomes = async () => {
    setActionStep(ActionStep.RESET_OUTCOMES)
    // setWishName('suggest')
    // setIsPageLoading(true)

    // const outcomeStrings = await APIQueue.getInstance().enqueue(() =>
    //   API.topicMetaToOutcomes(dto),
    // )
    // if (outcomeStrings.length > 0) {
    //   const newOutcomes = (outcomeStrings as string[]).map((outcomeString) => {
    //     return { title: outcomeString }
    //   })
    //   const newTopicDoc = { ...topicDoc! }
    //   newTopicDoc.learningOutcomes = newOutcomes
    //   setTopicDoc(newTopicDoc)
    // }

    // setIsPageLoading(false)
  }

  const processNotesChunkCallback = (message: string) => {
    console.log('processNotesChunkCallback', message)
    streamedNotes.current = streamedNotes.current + message

    const parts = streamedNotes.current.split('§')
    const newTopicDoc = { ...topicDoc! }
    if (parts.length > 0) {
      newTopicDoc.introduction!.notes = parts[0].trim()
    }
    if (parts.length > 1) {
      const sectionNotes = parts[1].split('¶')
      newTopicDoc.sections = sectionNotes.map((notes) => {
        return {
          body: '',
          notes: notes.trim(),
        }
      })
    }
    if (parts.length > 2) {
      newTopicDoc.conclusion!.notes = parts[2].trim()
    }
    setTopicDoc(newTopicDoc)
  }

  const generateAllNotesWithLibrary = async () => {
    const { name, notes, learningOutcomes } = topicDoc!

    streamedNotes.current = ''
    await APIQueue.getInstance().enqueue(
      async () =>
        // const { name, notes, learningOutcomes } = topicDoc!
        await API.metaLibraryToNotesStream(
          {
            name,
            notes,
            learningOutcomes,
            libraryContent: library?.content ?? '',
          },
          processNotesChunkCallback,
          0,
        ),
    )
    console.log('streamedNotes.current', streamedNotes.current)
  }

  const generateAllNotes = async () => {
    const { name, notes, learningOutcomes } = topicDoc!

    streamedNotes.current = ''
    await APIQueue.getInstance().enqueue(
      async () =>
        // const { name, notes, learningOutcomes } = topicDoc!
        await API.metaToNotesStream(
          {
            name,
            notes,
            learningOutcomes,
          },
          processNotesChunkCallback,
          0,
        ),
    )
    console.log('streamedNotes.current', streamedNotes.current)
  }

  const generateAllContentFromScratch = async () => {
    const promises = []
    promises.push(generateIntroduction(false))
    // generateIntroduction()
    for (let i = 0; i < topicDoc!.sections!.length; i++) {
      promises.push(generateMain(i, false))
    }
    promises.push(generateConclusion(false))

    await Promise.all(promises)
  }

  const generateAllContentFromLibrary = async () => {
    const promises = []
    promises.push(generateIntroduction(true))
    // generateIntroduction()
    for (let i = 0; i < topicDoc!.sections!.length; i++) {
      promises.push(generateMain(i, true))
    }
    promises.push(generateConclusion(true))

    await Promise.all(promises)
  }

  const startRegenerateAllNotesAndContentFromScratch = async () => {
    setActionStep(ActionStep.RESET_CONTENT_FROM_SCRATCH)
  }
  const startRegenerateAllNotesAndContentFromLibrary = async () => {
    setActionStep(ActionStep.RESET_CONTENT_FROM_LIBRARY)
  }

  const resetOutcomes = () => {
    const newTopicDoc = { ...topicDoc! }
    newTopicDoc.learningOutcomes = [{ title: '' }]
    setTopicDoc(newTopicDoc)
  }
  const resetTopicContent = () => {
    const newTopicDoc = { ...topicDoc! }
    newTopicDoc.introduction!.body = ''
    newTopicDoc.introduction!.notes = ''
    newTopicDoc.sections = [{ body: '', notes: '' }]
    newTopicDoc.conclusion!.body = ''
    newTopicDoc.conclusion!.notes = ''
    setTopicDoc(newTopicDoc)
  }

  // const startRegenerateAllNotesAndContent0 = async () => {
  //   console.log('handleClickSuggestNotes')
  //   setIsPageLoading(true)
  //   const { name, notes, learningOutcomes } = topicDoc!
  //   const result = await API.topicToGuides({
  //     name,
  //     notes,
  //     learningOutcomes,
  //   })
  //   setIsPageLoading(false)

  //   // use filter to get the first object in the array that has type === 'introduction'
  //   const introNotes = result?.find((guide) => guide.type === 'introduction')
  //     ?.notes
  //   const contentNotes = result
  //     ?.filter((guide) => guide.type === 'main')
  //     .map((guide) => guide.notes)
  //   const conclusionNotes = result?.find((guide) => guide.type === 'conclusion')
  //     ?.notes

  //   const newTopicDoc = { ...topicDoc! }
  //   newTopicDoc.introduction!.notes = introNotes

  //   const newSections = contentNotes?.map((notes) => {
  //     return {
  //       body: '',
  //       notes,
  //     }
  //   })
  //   newTopicDoc.sections = newSections
  //   newTopicDoc.conclusion!.notes = conclusionNotes
  //   setTopicDoc(newTopicDoc)
  //   // console.log('topicToGuides:', result)
  //   setShouldGenerateAll(true)
  // }
  const handleClickRegenerateAllFromScratch = async () => {
    // setConfirmCallback(() => {
    //   console.log('confirmCallback')
    // })
    setConfirmCallback(() => startRegenerateAllNotesAndContentFromScratch)
    setIsConfirmOpen(true)
  }

  const handleClickRegenerateAllFromLibrary = async () => {
    // setConfirmCallback(() => {
    //   console.log('confirmCallback')
    // })
    setConfirmCallback(() => startRegenerateAllNotesAndContentFromLibrary)
    setIsConfirmOpen(true)
  }

  const isAllowedToAddSection = (): boolean => {
    const lastSection = topicDoc!.sections?.[topicDoc!.sections.length - 1]
    if (lastSection?.body?.trim() || lastSection?.notes?.trim()) {
      return true
    }
    return false
  }
  const handleClickAddSection = () => {
    if (!isAllowedToAddSection()) return
    const newTopicDoc = { ...topicDoc! }
    newTopicDoc.sections?.push({
      _id: '',
      body: '',
    })
    setTopicDoc(newTopicDoc)
    setLastAction(Actions.ADD_SECTION)
  }

  const isContentEmpty = () => {
    //check all sections empty
    let allSectionsEmpty = true
    for (let i = 0; i < topicDoc!.sections!.length; i++) {
      const section = topicDoc!.sections![i]
      if (section.body?.trim() || section.notes?.trim()) {
        allSectionsEmpty = false
        break
      }
    }

    if (
      !topicDoc?.introduction?.body?.trim() &&
      !topicDoc?.introduction?.notes?.trim() &&
      allSectionsEmpty &&
      !topicDoc?.conclusion?.body?.trim() &&
      !topicDoc?.conclusion?.notes?.trim()
    ) {
      return true
    }
    return false
  }

  // //useEffect
  // useEffect(() => {

  //   const fetchData = async () => {
  //     try {
  //       const result = await API.getAllTopicDocs();
  //       console.log('result', result);
  //       // Do something with the result if needed
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   fetchData(); // Call the async function immediately

  // }, [])
  useEffect(() => {
    // This effect will run whenever myState changes
    if (actionStep === ActionStep.RESET_OUTCOMES) {
      resetOutcomes()
      setActionStep(ActionStep.GENERATE_OUTCOMES)
    } else if (actionStep === ActionStep.GENERATE_OUTCOMES) {
      const asyncFunction = async () => {
        await generateLearningOutcomes()
        setActionStep(ActionStep.NONE)
      }
      asyncFunction()
    } else if (actionStep === ActionStep.RESET_CONTENT_FROM_SCRATCH) {
      resetTopicContent()
      setActionStep(ActionStep.GENERATE_NOTES_FROM_SCRATCH)
    } else if (actionStep === ActionStep.GENERATE_NOTES_FROM_SCRATCH) {
      const asyncFunction = async () => {
        await generateAllNotes()
        setActionStep(ActionStep.GENERATE_CONTENT_FROM_SCRATCH)
      }
      asyncFunction()
    } else if (actionStep === ActionStep.GENERATE_CONTENT_FROM_SCRATCH) {
      const asyncFunction = async () => {
        await generateAllContentFromScratch()
        console.log('generateAllContent done')
        setActionStep(ActionStep.DONE)
      }
      asyncFunction()
    } else if (actionStep === ActionStep.RESET_CONTENT_FROM_LIBRARY) {
      resetTopicContent()
      setActionStep(ActionStep.GENERATE_NOTES_FROM_LIBRARY)
    } else if (actionStep === ActionStep.GENERATE_NOTES_FROM_LIBRARY) {
      const asyncFunction = async () => {
        await generateAllNotesWithLibrary()

        setActionStep(ActionStep.GENERATE_CONTENT_FROM_LIBRARY)
      }
      asyncFunction()
    } else if (actionStep === ActionStep.GENERATE_CONTENT_FROM_LIBRARY) {
      const asyncFunction = async () => {
        await generateAllContentFromLibrary()
        console.log('generateAllContent done')
        setActionStep(ActionStep.DONE)
      }
      asyncFunction()
    } else if (actionStep === ActionStep.REVISE_CONTENT) {
      const asyncFunction = async () => {
        await reviseContent()
        console.log('revision done')
        setActionStep(ActionStep.DONE)
      }
      asyncFunction()
    }
  }, [actionStep])

  // useEffect(() => {
  //   // This effect will run whenever myState changes
  //   if (!topicDoc) {
  //     const newTooltipStates = [...tooltipStates]
  //     for (let i = 1; i < newTooltipStates.length; i++) {
  //       newTooltipStates[i] = 0
  //     }
  //   }
  // }, [tooltipStates, topicDoc])

  useEffect(() => {
    // This effect will run whenever myState changes
    if (outcomeFocus > 0) return
    const timerId = setTimeout(() => {
      const newTopicDoc = { ...topicDoc! }
      const filteredLearningOutcomes = newTopicDoc.learningOutcomes.filter(
        (learningOutcome) => learningOutcome.title.trim() !== '',
      )
      newTopicDoc.learningOutcomes =
        filteredLearningOutcomes.length === 0
          ? [{ title: '' }]
          : filteredLearningOutcomes
      setTopicDoc(newTopicDoc)
    }, 200) // Adjust the time (in milliseconds) according to your needs

    // Clean up the timer when the component unmounts or when myState changes again
    return () => clearTimeout(timerId)
  }, [outcomeFocus])

  useEffect(() => {
    // Focus on the first input element after rendering
    if (lastAction === Actions.ADD_OUTCOME) {
      outcomeRef.current?.focus()
      setLastAction(Actions.NONE)
      return
    }
  }, [lastAction])

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newTopicDoc = { ...topicDoc! }
    newTopicDoc.name = event.target.value
    setTopicDoc(newTopicDoc)
  }

  const handleNotesChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const newTopicDoc = { ...topicDoc! }
    newTopicDoc.notes = event.target.value
    setTopicDoc(newTopicDoc)
  }

  //rendering
  const renderTopicMeta = (text: string, notes?: string) => {
    return (
      <>
        <div></div>
        <div></div>
      </>
    )
  }

  const renderLearningOutcome = (
    learningOutcome: LearningOutcome,
    index: number,
  ) => {
    const handleBodyChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const newTopicDoc = { ...topicDoc! }
      newTopicDoc.learningOutcomes[index].title = event.target.value
      setTopicDoc(newTopicDoc)
    }
    const handleFocusLost = () => {
      console.log('focus lost')
      setOutcomeFocus(0)
    }
    const handleFocus = () => {
      console.log('focus')
      setOutcomeFocus(1)
    }

    return (
      // <div className={styles.grid}>
      <div className={styles.outcomecontainer}>
        <TextareaAutosize
          className={styles.textarea}
          onChange={handleBodyChange}
          value={learningOutcome.title}
          onBlur={handleFocusLost}
          onFocus={handleFocus}
          ref={
            index === topicDoc!.learningOutcomes.length - 1 ? outcomeRef : null
          }
          placeholder="Identify/understand/describe/explain ..."
          data-tooltip-id={index === 0 ? 'tooltip-2' : ''}
        />
      </div>
      // <div></div>
      // <div></div>
      // </div>
    )
  }

  const renderIntroduction = (block: Block) => {
    const handleBodyChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const newTopicDoc = { ...topicDoc! }
      newTopicDoc.introduction!.body = event.target.value
      setTopicDoc(newTopicDoc)
    }
    const handleNotesChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const newTopicDoc = { ...topicDoc! }
      newTopicDoc.introduction!.notes = event.target.value
      setTopicDoc(newTopicDoc)
    }
    const handleClickGenerateIntroduction = async () => {
      generateIntroduction(library !== undefined)
    }

    return (
      <>
        <TextareaAutosize
          minRows={4}
          className={styles.contenttextarea}
          onChange={handleBodyChange}
          value={block.body}
          placeholder="..."
        />
        <div>
          {(block.isLoading ||
            (actionStep >= ActionStep.GENERATE_CONTENT_FROM_SCRATCH &&
              actionStep < ActionStep.DONE)) && <LoadingIndicator />}
          {!block.isLoading &&
            (actionStep === ActionStep.NONE ||
              actionStep === ActionStep.DONE) && (
              <Button
                className={`${styles.button} ${styles.aibutton}`}
                onClick={handleClickGenerateIntroduction}
              >
                {'⬅ Generate'}
              </Button>
            )}
        </div>
        <div>
          <div className={styles.fieldlabel}>Introduction Outlines</div>
          <TextareaAutosize
            placeholder="Notes to generate content"
            minRows={2}
            className={styles.notes}
            onChange={handleNotesChange}
            value={block.notes}
          />
        </div>
      </>
    )
  }

  const generateIntroduction = async (withGuide: boolean) => {
    let newTopicDoc = { ...topicDoc! }
    newTopicDoc.introduction!.isLoading = true
    setTopicDoc(newTopicDoc)

    const processChunkCallback = (
      message: string,
      accumulatedMessage: string,
      index?: number,
    ) => {
      newTopicDoc = { ...topicDoc! }
      // newTopicDoc.introduction!.body = newTopicDoc.introduction!.body + message
      newTopicDoc.introduction!.body = accumulatedMessage
      setTopicDoc(newTopicDoc)
    }

    newTopicDoc = { ...topicDoc! }
    newTopicDoc.introduction!.body = ''
    setTopicDoc(newTopicDoc)

    if (withGuide) {
      await APIQueue.getInstance().enqueue(
        async () =>
          await API.topicLibraryAndGuideToContentStream(
            {
              topicDoc: topicDoc!,
              generateType: 'introduction',
              generateIndex: -1,
              libraryContent: library?.content ?? '',
            },
            processChunkCallback,
            0,
          ),
      )
    } else {
      await APIQueue.getInstance().enqueue(
        async () =>
          await API.topicAndGuideToContentStream(
            {
              topicDoc: topicDoc!,
              generateType: 'introduction',
              generateIndex: -1,
            },
            processChunkCallback,
            0,
          ),
      )
    }

    // console.log('result', result)

    newTopicDoc = { ...topicDoc! }
    newTopicDoc.introduction!.isLoading = false
    setTopicDoc(newTopicDoc)
  }
  const generateMain = async (sectionIndex: number, withGuide: boolean) => {
    let newTopicDoc = { ...topicDoc! }
    newTopicDoc.sections![sectionIndex].isLoading = true
    newTopicDoc.sections![sectionIndex].body = ''
    setTopicDoc(newTopicDoc)

    const processChunkCallback = (
      message: string,
      accumulatedMessage: string,
      index?: number,
    ) => {
      const sectionIndex = index ?? 0
      newTopicDoc = { ...topicDoc! }
      newTopicDoc.sections![sectionIndex].body =
        newTopicDoc.sections![sectionIndex].body + message
      setTopicDoc(newTopicDoc)
    }

    if (withGuide) {
      await APIQueue.getInstance().enqueue(
        async () =>
          await API.topicLibraryAndGuideToContentStream(
            {
              topicDoc: topicDoc!,
              generateType: 'main',
              generateIndex: sectionIndex,
              libraryContent: library?.content ?? '',
            },
            processChunkCallback,
            sectionIndex,
          ),
      )
    } else {
      await APIQueue.getInstance().enqueue(
        async () =>
          await API.topicAndGuideToContentStream(
            {
              topicDoc: topicDoc!,
              generateType: 'main',
              generateIndex: sectionIndex,
            },
            processChunkCallback,
            sectionIndex,
          ),
      )
    }

    newTopicDoc = { ...topicDoc! }
    newTopicDoc.sections![sectionIndex].isLoading = false
    setTopicDoc(newTopicDoc)
  }
  const generateConclusion = async (withGuide: boolean) => {
    let newTopicDoc = { ...topicDoc! }
    newTopicDoc.conclusion!.isLoading = true
    setTopicDoc(newTopicDoc)

    const processChunkCallback = (message: string) => {
      newTopicDoc = { ...topicDoc! }
      newTopicDoc.conclusion!.body = newTopicDoc.conclusion!.body + message
      setTopicDoc(newTopicDoc)
    }

    newTopicDoc = { ...topicDoc! }
    newTopicDoc.conclusion!.body = ''
    setTopicDoc(newTopicDoc)

    if (withGuide) {
      await APIQueue.getInstance().enqueue(
        async () =>
          await API.topicLibraryAndGuideToContentStream(
            {
              topicDoc: topicDoc!,
              generateType: 'conclusion',
              generateIndex: -1,
              libraryContent: library?.content ?? '',
            },
            processChunkCallback,
            0,
          ),
      )
    } else {
      await APIQueue.getInstance().enqueue(
        async () =>
          await API.topicAndGuideToContentStream(
            {
              topicDoc: topicDoc!,
              generateType: 'conclusion',
              generateIndex: -1,
            },
            processChunkCallback,
            0,
          ),
      )
    }

    newTopicDoc = { ...topicDoc! }
    newTopicDoc.conclusion!.isLoading = false
    setTopicDoc(newTopicDoc)
  }

  const renderSection = (section: Block, index: number) => {
    const handleBodyChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const newTopicDoc = { ...topicDoc! }
      if (newTopicDoc.sections && newTopicDoc.sections[index]) {
        newTopicDoc.sections[index].body = event.target.value
      }
      setTopicDoc(newTopicDoc)
    }
    const handleNotesChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const newTopicDoc = { ...topicDoc! }
      if (newTopicDoc.sections && newTopicDoc.sections[index]) {
        newTopicDoc.sections[index].notes = event.target.value
      }
      setTopicDoc(newTopicDoc)
    }

    const handleFocus = () => {
      console.log('focus')
      sectionFocus.current = 1
      if (sectionIdleTimerId) {
        clearTimeout(sectionIdleTimerId)
        setSectionIdleTimerId(null)
      }
    }

    const handleFocusLost = () => {
      console.log('focus lost')
      if ((topicDoc!.sections?.length ?? 0) > 1) {
        sectionFocus.current = 0

        const timerId = setTimeout(() => {
          console.log('sectionFocus.current', sectionFocus.current)
          const testIndex = topicDoc!.sections!.length - 1
          if (sectionFocus.current === 1) return
          if (
            !topicDoc!.sections![testIndex].body?.trim() &&
            !topicDoc!.sections![testIndex].notes?.trim()
          ) {
            const newTopicDoc = { ...topicDoc! }
            newTopicDoc.sections!.splice(testIndex, 1)
            setTopicDoc(newTopicDoc)
          }
        }, 1000)
        setSectionIdleTimerId(timerId)
      }
    }

    const handleClickGenerateMain = async () => {
      generateMain(index, library !== undefined)
    }

    return (
      <div className={`${styles.grid} ${styles.mainsection}`}>
        <div>
          <TextareaAutosize
            minRows={4}
            className={styles.contenttextarea}
            onChange={handleBodyChange}
            value={section.body}
            onFocus={handleFocus}
            onBlur={handleFocusLost}
            placeholder="..."
          />
        </div>
        <div>
          {(section.isLoading ||
            (actionStep >= ActionStep.GENERATE_CONTENT_FROM_SCRATCH &&
              actionStep < ActionStep.DONE)) && <LoadingIndicator />}
          {!section.isLoading &&
            (actionStep === ActionStep.NONE ||
              actionStep === ActionStep.DONE) && (
              <Button
                className={`${styles.button} ${styles.aibutton}`}
                onClick={handleClickGenerateMain}
                disabled={section.notes?.trim() === '' || !section.notes}
                data-tooltip-id={index === 0 ? 'tooltip-5' : ''}
              >
                {'⬅ Generate'}
              </Button>
            )}
        </div>
        <div>
          <div className={styles.fieldlabel}>Section Outlines</div>
          <TextareaAutosize
            placeholder="Notes to generate content"
            minRows={2}
            className={styles.notes}
            onChange={handleNotesChange}
            value={section.notes}
            onFocus={handleFocus}
            onBlur={handleFocusLost}
          />
        </div>
      </div>
    )
  }

  const renderConclusion = (block: Block) => {
    const handleBodyChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const newTopicDoc = { ...topicDoc! }
      newTopicDoc.conclusion!.body = event.target.value
      setTopicDoc(newTopicDoc)
    }
    const handleNotesChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const newTopicDoc = { ...topicDoc! }
      newTopicDoc.conclusion!.notes = event.target.value
      setTopicDoc(newTopicDoc)
    }
    const handleClickGenerateConclusion = async () => {
      generateConclusion(library !== undefined)
    }

    return (
      <>
        <TextareaAutosize
          minRows={4}
          className={styles.contenttextarea}
          onChange={handleBodyChange}
          value={block.body}
          placeholder="..."
        />
        <div>
          {(block.isLoading ||
            (actionStep >= ActionStep.GENERATE_CONTENT_FROM_SCRATCH &&
              actionStep < ActionStep.DONE)) && <LoadingIndicator />}
          {!block.isLoading &&
            (actionStep === ActionStep.NONE ||
              actionStep === ActionStep.DONE) && (
              <Button
                className={`${styles.button} ${styles.aibutton}`}
                onClick={handleClickGenerateConclusion}
              >
                {'⬅ Generate'}
              </Button>
            )}
        </div>
        <div>
          <div className={styles.fieldlabel}>Conclusion Outlines</div>
          <TextareaAutosize
            placeholder="Notes to generate content"
            minRows={2}
            className={styles.notes}
            onChange={handleNotesChange}
            value={block.notes}
          />
        </div>
      </>
    )
  }

  const handleClickTooltip = (index: number, scrollTarget?: string) => {
    console.log('handleClickTooltip')
    // if (tooltipStates[index] === 0) return
    const newTooltipStates = [...tooltipStates]

    //switch off
    newTooltipStates[index] = 0

    //switch on next
    let nextIndex = index + 1 < tooltipStates.length ? index + 1 : 0
    if (nextIndex) {
      newTooltipStates[nextIndex] = 1
    } else {
      storePreference({ ...preference, toolTip: 0 })
    }
    const element = document.querySelector(
      `[data-tooltip-id="tooltip-${nextIndex}"]`,
    )
    if (element) {
      if (scrollTarget !== undefined) {
        console.log('scrollTarget', scrollTarget)
        const target = document.getElementById(scrollTarget)
        target?.scrollIntoView({ behavior: 'smooth', block: 'center' })
        // window.scrollTo({ behavior: 'smooth', top: scrollPosition })
      } else {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
      // element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'end' })
      // window.scrollTo({ behavior: 'smooth', top: 0 })
    }
    setTooltipStates(newTooltipStates)
  }

  const isToolTipOpen = (index: number) => {
    return tooltipStates[0] === 1 && tooltipStates[index] === 1
  }

  const commentChunkCallback = (
    message: string,
    accumulatedMessage: string,
  ) => {
    const newTopicDoc = { ...topicDoc! }
    if (!newTopicDoc.review) {
      newTopicDoc.review = {
        comments: '',
      }
    }
    newTopicDoc.review.comments = accumulatedMessage
    setTopicDoc(newTopicDoc)
  }
  const revisionChunkCallback = (
    message: string,
    accumulatedMessage: string,
  ) => {
    console.log('revisionChunkCallback', message)

    const newTopicDoc = { ...topicDoc! }

    const parts = accumulatedMessage.split('§')
    if (parts.length > 0) {
      newTopicDoc.introduction!.body = parts[0].trim()
    }
    if (parts.length > 1) {
      const sectionContents = parts[1].split('¶')
      sectionContents.forEach((section, index) => {
        newTopicDoc.sections![index].body = section.trim()
      })
    }
    if (parts.length > 2) {
      newTopicDoc.conclusion!.body = parts[2].trim()
    }
    setTopicDoc(newTopicDoc)
  }
  const handleClickGenerateComments = async () => {
    const dto = {
      topicDoc: topicDoc!,
      dimensions: 'all',
    }
    await APIQueue.getInstance().enqueue(() =>
      API.reviewWritingStream(dto, commentChunkCallback),
    )
  }
  const handleClickRevise = async () => {
    setActionStep(ActionStep.REVISE_CONTENT)
  }
  const reviseContent = async () => {
    const dto = {
      topicDoc: topicDoc!,
      comments: '',
      actions: '',
    }
    const result = await APIQueue.getInstance().enqueue(() =>
      API.reviseWritingStream(dto, revisionChunkCallback),
    )
    console.log('result', result)
  }

  return (
    <>
      <PopupConfirm
        isOpen={isConfirmOpen}
        title={
          isContentEmpty()
            ? 'Generate all topic content?'
            : 'Regenerate all topic content?'
        }
        description={
          isContentEmpty()
            ? 'This will create section outlines based on the topic notes. And then the content for each section for the whole topic.'
            : 'This will remove the existing content. Are you sure you want to remove and regenerate all content?'
        }
        onClose={() => {
          setIsConfirmOpen(false)
        }}
        onConfirm={() => {
          confirmCallback()
        }}
      ></PopupConfirm>
      <div className={`${styles.background}`} id="scroll-container">
        <div className={styles.scrollcontainer}>
          <Grow
            in={topicDoc !== undefined}
            style={{ transformOrigin: '50% 50vh 0' }}
          >
            {/*  */}
            <div className={styles.pagearea}>
              <EditorTags selectedIndex={1} />
              <div className={`${styles.pagecontainer}`}>
                <div>
                  <div className={styles.topgrid}>
                    <div className={styles.topgridcolumn1}>
                      <span className={styles.fieldlabel}>Topic Name</span>
                      <div>
                        <input
                          placeholder="Noun or phrase < 10 words"
                          className={styles.textarea}
                          onChange={handleInputChange}
                          value={topicDoc!.name}
                          data-tooltip-id="tooltip-1"
                          // data-event={'click'}
                        />
                        <Tooltip
                          clickable={true}
                          place="right"
                          // data-tooltip-id="tooltip-1"
                          id="tooltip-1"
                          isOpen={isToolTipOpen(1)}
                          className={`${styles.tooltip} ${
                            isToolTipOpen(1) ? '' : 'hidden'
                          }`}
                          border={'1px solid rgb(186, 97, 255)'}
                          // border cannot be set in css
                          // openOnClick={true}
                        >
                          <div
                            className={`${styles.tooltipcontainer}`}
                            onClick={() => handleClickTooltip(1)}
                          >
                            <h3>Step 1: Input the topic name</h3>
                            <li>This cannot be blank</li>
                          </div>
                        </Tooltip>
                      </div>
                      <div className={styles.centercontainer}>
                        {actionStep > ActionStep.NONE &&
                        actionStep < ActionStep.DONE ? (
                          <LoadingIndicator />
                        ) : (
                          <Button
                            className={`${styles.button} ${styles.aibutton}`}
                            onClick={handleClickSuggestLearningOutcomes}
                          >
                            {'Suggest learning outcomes ⬇'}
                          </Button>
                        )}
                      </div>
                      <span className={styles.fieldlabel}>
                        Learning outcomes
                      </span>

                      <TransitionGroup>
                        {topicDoc!.learningOutcomes.map(
                          (learningOutcome, index) => {
                            return (
                              <Collapse key={`learningoutcome-${index}`}>
                                {renderLearningOutcome(learningOutcome, index)}
                              </Collapse>
                            )
                          },
                        )}
                      </TransitionGroup>
                      <Tooltip
                        clickable={true}
                        place="right"
                        // data-tooltip-id="tooltip-2"
                        id="tooltip-2"
                        isOpen={isToolTipOpen(2)}
                        className={`${styles.tooltip} ${
                          isToolTipOpen(2) ? '' : 'hidden'
                        }`}
                        border={'1px solid rgb(186, 97, 255)'}
                        // border cannot be set in css
                        // openOnClick={true}
                      >
                        <div
                          className={`${styles.tooltipcontainer}`}
                          onClick={() => handleClickTooltip(2)}
                        >
                          <h3>Step 2: Input the learning outcomes</h3>
                          <li>Add more learning outcomes if necessary</li>
                          {/* </ul> */}
                        </div>
                      </Tooltip>
                      <div className={styles.centercontainer}>
                        <Button
                          className={styles.button}
                          onClick={() => {
                            handleClickAddOutcome()
                          }}
                        >
                          Add a learning outcome
                        </Button>
                      </div>
                      {/* {renderTopicMeta(topicDoc!.name, topicDoc!.notes)} */}

                      <div />
                      <div></div>
                    </div>
                    <div></div>
                    <div className={styles.topgridcolumn3}>
                      <span className={styles.fieldlabel} id="notes-label">
                        Notes
                      </span>
                      <Tooltip
                        clickable={true}
                        place="left"
                        // data-tooltip-id="tooltip-2"
                        id="tooltip-3"
                        isOpen={isToolTipOpen(3)}
                        className={`${styles.tooltip} ${
                          isToolTipOpen(3) ? '' : 'hidden'
                        }`}
                        border={'1px solid rgb(186, 97, 255)'}
                        // border cannot be set in css
                        // openOnClick={true}
                      >
                        <div
                          className={`${styles.tooltipcontainer}`}
                          onClick={() => handleClickTooltip(3)}
                        >
                          <h3>Step 3: Input some notes about the topic</h3>
                          <b>
                            <li>
                              These points will be used to generate outlines for
                              the sections
                            </li>
                          </b>
                          <li>
                            If you don't have any notes, you can skip this step
                          </li>
                          {/* </ul> */}
                        </div>
                      </Tooltip>
                      <TextareaAutosize
                        placeholder="Notes about the topic"
                        minRows={2}
                        className={styles.notes}
                        onChange={handleNotesChange}
                        value={topicDoc!.notes}
                        data-tooltip-id="tooltip-3"
                      />

                      {/* <Button
                    className={styles.aibutton}
                    onClick={() => {
                      handleClickSuggestNotes()
                    }}
                    data-tooltip-id="tooltip-4"
                  >
                    {'Suggest notes for the content ⬇'}
                  </Button> */}
                      <Tooltip
                        clickable={true}
                        place="left"
                        // data-tooltip-id="tooltip-2"
                        id="tooltip-4"
                        isOpen={isToolTipOpen(4)}
                        className={`${styles.tooltip} ${
                          isToolTipOpen(4) ? '' : 'hidden'
                        }`}
                        border={'1px solid rgb(186, 97, 255)'}
                        // border cannot be set in css
                        // openOnClick={true}
                      >
                        <div
                          className={`${styles.tooltipcontainer}`}
                          onClick={() => handleClickTooltip(4)}
                        >
                          <h3>
                            Step 4: Generate section outlines for sections in
                            the topic
                          </h3>
                          <h3>
                            Please be aware that executing this action will
                            eliminate all sections below:
                          </h3>
                          <b>
                            <li>introduction</li>
                            <li>All section content</li>
                            <li>Conclusion</li>
                          </b>
                          {/* </ul> */}
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className={styles.centerrow}>
                  {actionStep > ActionStep.NONE &&
                  actionStep < ActionStep.DONE ? (
                    <LoadingIndicator />
                  ) : (
                    <>
                      <Button
                        className={styles.aibutton}
                        onClick={() => {
                          handleClickRegenerateAllFromScratch()
                        }}
                        data-tooltip-id="tooltip-4"
                      >
                        {isContentEmpty()
                          ? '⬇ Generate all content ⬇'
                          : '⬇ Regenerate all content ⬇'}
                      </Button>
                      {' | '}
                      <Button
                        className={styles.aibutton}
                        onClick={() => {
                          handleClickRegenerateAllFromLibrary()
                        }}
                        data-tooltip-id="tooltip-4"
                      >
                        {isContentEmpty()
                          ? '⬇ Generate content with library ⬇'
                          : '⬇ Regenerate content with library ⬇'}
                      </Button>
                    </>
                  )}
                </div>

                {topicDoc!.introduction && (
                  <div>
                    <div className={styles.fieldlabel}>Introduction</div>
                    <div className={styles.grid}>
                      {renderIntroduction(topicDoc!.introduction)}
                    </div>
                  </div>
                )}

                {topicDoc!.sections && (
                  <div>
                    <div className={styles.fieldlabel}>Content</div>
                    <TransitionGroup>
                      {topicDoc!.sections?.map((section, index) => {
                        return (
                          <Collapse key={`section-${index}`}>
                            {renderSection(section, index)}
                          </Collapse>
                        )
                      })}
                    </TransitionGroup>
                    <Tooltip
                      clickable={true}
                      place="top"
                      // data-tooltip-id="tooltip-2"
                      id="tooltip-5"
                      isOpen={isToolTipOpen(5)}
                      className={`${styles.tooltip} ${
                        isToolTipOpen(5) ? '' : 'hidden'
                      }`}
                      border={'1px solid rgb(186, 97, 255)'}
                      // border cannot be set in css
                      // openOnClick={true}
                    >
                      <div
                        className={`${styles.tooltipcontainer}`}
                        onClick={() => handleClickTooltip(5)}
                      >
                        <h3>Step 5: Generate section content from notes</h3>
                        <b>
                          <li>This will overwrite the content on the left</li>
                        </b>
                        <li>The notes cannot be blank</li>
                        {/* </ul> */}
                      </div>
                    </Tooltip>
                    <div className={styles.grid}>
                      <div>
                        <Button
                          className={styles.button}
                          onClick={() => {
                            handleClickAddSection()
                          }}
                          disabled={!isAllowedToAddSection()}
                        >
                          add a section
                        </Button>
                      </div>
                    </div>
                  </div>
                )}

                {topicDoc!.conclusion && (
                  <div>
                    <div className={styles.fieldlabel}>Conclusion</div>
                    <div className={styles.grid}>
                      {renderConclusion(topicDoc!.conclusion)}
                    </div>
                  </div>
                )}

                <div className={styles.grid}>
                  <div>
                    {actionStep > ActionStep.NONE &&
                    actionStep < ActionStep.DONE ? (
                      <LoadingIndicator />
                    ) : (
                      <Button
                        className={`${styles.button} ${styles.aibutton}`}
                        onClick={handleClickGenerateComments}
                        // onClick={handleClickGenerateIntroduction}
                      >
                        {'⬆ Review'}
                      </Button>
                    )}
                    <TextareaAutosize
                      minRows={4}
                      className={`${styles.contenttextarea} ${styles.comments}`}
                      value={topicDoc?.review?.comments}
                      placeholder="..."
                    />
                    {actionStep > ActionStep.NONE &&
                    actionStep < ActionStep.DONE ? (
                      <LoadingIndicator />
                    ) : (
                      <Button
                        className={`${styles.button} ${styles.aibutton}`}
                        onClick={handleClickRevise}
                        // onClick={handleClickGenerateIntroduction}
                      >
                        {'⬆ Revise'}
                      </Button>
                    )}
                  </div>
                  <div />
                  <div />
                </div>
              </div>
            </div>
          </Grow>
        </div>
      </div>
    </>
  )
}
