import React, { ReactNode } from 'react'
import styles from './Popup.module.css'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { PopupContentType, useUIContext } from 'context/UIProvider'

interface PopupProps {
  popupName: string
  children: ReactNode
  buttons?: ReactNode
  onSubmit?: () => void
  onClose?: () => void
  fullScreen?: boolean
  direction?: 'up' | 'left'
}

export const TransitionUp = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})
export const TransitionLeft = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />
})

export const Popup: React.FC<PopupProps> = ({
  children,
  buttons,
  onClose,
  onSubmit,
  fullScreen,
  direction = 'up',
  popupName,
}) => {
  //   if (!isVisible) {
  //     return null;
  //   }
  const [isOpen, setIsOpen] = React.useState<boolean>(true)
  const { popupContentType, setPopupContentType } = useUIContext()

  const handleClose = () => {
    setIsOpen(false)
    setPopupContentType(PopupContentType.None)
    onClose && onClose()
  }
  const handleSubimt = () => {
    setIsOpen(false)
    onSubmit && onSubmit()
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={popupContentType === popupName}
      TransitionComponent={direction === 'up' ? TransitionUp : TransitionLeft}
      //   keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-merged-doc"
      PaperProps={{
        sx: {
          borderRadius: '0px',
          padding: '0px',
          overflow: 'hidden',
          backgroundColor: 'rgba(0, 0, 0, 0)',
        },
      }}
    >
      <DialogContent sx={{ padding: '0px' }}>{children}</DialogContent>
      <DialogActions className={styles.actionbarcolor}>{buttons}</DialogActions>
    </Dialog>
  )
}
