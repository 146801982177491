import React, { ChangeEvent, useEffect, useRef } from 'react'
import styles from './LibraryEditor.module.css'
import { useDataContext } from 'context/DataProvider'
import { useUIContext } from 'context/UIProvider'
import { Button, Collapse, IconButton, TextareaAutosize } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { Library } from 'apis/entities/topic.entity'
import { API } from 'apis/API'

interface LibraryProps {
  // Define the props for your component here
  contentRef: React.RefObject<HTMLDivElement>
}

const LibraryEditor: React.FC<LibraryProps> = ({ contentRef }) => {
  // Implement your component logic here
  const {
    topicDoc,
    library,
    setLibrary,
    loadLibraryById,
    setTopicDoc,
    createLocalLibrary,
  } = useDataContext()
  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false)
  const [libraries, setLibraries] = React.useState<Library[]>([])

  const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    if (!library) {
      return
    }
    const newLibrary = { ...library }
    newLibrary.content = event.target.value
    console.log('newLibraryId', newLibrary._id)
    setLibrary(newLibrary)
  }

  const handleClickHambugerMenu = async (): Promise<void> => {
    if (!isMenuOpen) {
      const newLibraries = await API.getAllLibraries()
      console.log('newLibraries', newLibraries)
      if (newLibraries) {
        setLibraries(newLibraries)
      }
      setIsMenuOpen(true)
    } else {
      setIsMenuOpen(false)
    }
  }

  const handleMenuItemClick = (libraryId: string): void => {
    loadLibraryById(libraryId)

    if (topicDoc) {
      const newTopicDoc = { ...topicDoc }
      newTopicDoc.libraryId = libraryId
      setTopicDoc(newTopicDoc)
    }
    setIsMenuOpen(false)
  }

  const handleClickCreateLibrary = async (): Promise<void> => {
    if (topicDoc) {
      const newTopicDoc = { ...topicDoc }
      newTopicDoc.libraryId = undefined
      setTopicDoc(newTopicDoc)
    }
    createLocalLibrary()
    setIsMenuOpen(false)
  }

  return (
    <div className={styles.pagecontainer} ref={contentRef}>
      <div className={styles.layout}>
        <IconButton
          className={styles.menuButton}
          aria-controls="menu"
          aria-haspopup="true"
          onClick={handleClickHambugerMenu}
          color="inherit"
        >
          <MenuIcon style={{ color: '#FFFFFF' }} />
        </IconButton>

        <div
          // in={isMenuOpen}
          className={`${
            isMenuOpen ? styles.menucontaineropen : styles.menucontainerclosed
          }`}
        >
          <div className={styles.menu}>
            <div className={styles.menulabel}>Choose a Library:</div>
            {libraries.map((lib) => {
              return (
                <div
                  key={lib._id}
                  className={styles.menuitem}
                  onClick={() => {
                    handleMenuItemClick(lib._id)
                  }}
                >
                  {library?._id === lib._id ? (
                    <b>{lib.title}</b>
                  ) : (
                    <>{lib.title}</>
                  )}
                </div>
              )
            })}
            <Button
              className={styles.menuitemcreate}
              onClick={handleClickCreateLibrary}
            >
              Create a new library+
            </Button>
          </div>
          {/* </div> */}
        </div>
        <textarea
          className={styles.content}
          value={library?.content}
          onChange={handleTextChange}
        ></textarea>
      </div>
    </div>
  )
}

export default LibraryEditor
