import React from "react";
import styles from './FullscreenLayout.module.css'
interface Props {
  children: React.ReactNode;
  elementType?: keyof JSX.IntrinsicElements;
  className?: string; // Update the type to accept a string directly
}

export default function FullScreenLayout({
  children,
  elementType: ElementType = "main",
  className,
}: Props): JSX.Element {
  return (
    <div className={styles.container}>
      <ElementType className={className}>{children}</ElementType>
    </div>
  );
}
